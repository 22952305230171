import { currentAccessToken } from "~/utils/keycloak";

import type { Route } from "~/routes/core/routes";

import type { CustomHeaders } from "~/types/fetch";

type MethodType = "GET" | "POST" | "PUT" | "DELETE";

const ENABLE_LOCAL_CACHE = true;

const pendingPromises: Record<string, Promise<any>> = {};

export async function useCustomFetch<T> (
    requestUrl: string,
    requestMethod: MethodType = "GET",
    requestHeaders: CustomHeaders = {},
    requestBody: object | null = null,
    noBearer = false,
    withCache = true
): Promise<T> {
    try {
        const cacheKey = JSON.stringify({ requestUrl, requestMethod, requestHeaders, requestBody });
        if (ENABLE_LOCAL_CACHE && withCache) {
            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
                const { data, timestamp } = JSON.parse(cachedData);
                const config = useRuntimeConfig().public;
                const isExpired = Date.now() - timestamp > parseInt(`${config.LOCAL_CACHE_DURATION ?? 20000}`);
                if (!isExpired) {
                    return data;
                }
                localStorage.removeItem(cacheKey);
            }

            if (pendingPromises[cacheKey] !== undefined) {
                return await pendingPromises[cacheKey];
            }
        }

        const options: {
            method: MethodType
            headers: CustomHeaders
            body?: string
        } = {
            method: requestMethod,
            headers: {
                ...requestHeaders
            }
        };

        // @ts-ignore
        if (!noBearer && !options?.headers?.Authorization) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${currentAccessToken.value}`
            };
        }

        if (requestMethod !== "GET" && requestBody !== null && options.headers) {
            options.body = JSON.stringify(requestBody);
            options.headers = {
                ...options.headers,
                "Content-Type": "application/json"
            };
        }

        const promise = useFetch(requestUrl, {
            ...options,
            key: requestUrl
        }).then(({ data }) => {
            const result = data.value as T;
            if (ENABLE_LOCAL_CACHE && withCache) {
                localStorage.setItem(cacheKey, JSON.stringify({ data: result, timestamp: Date.now() }));
                delete pendingPromises[cacheKey];
            }
            return result;
        });

        pendingPromises[cacheKey] = promise;
        return await promise;
    } catch (e) {
        console.error(e);
        return null as unknown as T;
    }
}

type RequestObject = Record<string, any>;

function replaceUrlParams (url: string, requestBody: RequestObject): string {
    const regex = /\[(\w+)]/g;
    return url.replace(regex, (_, paramName: string) => {
        if (requestBody[paramName] !== undefined) {
            return requestBody[paramName];
        } else {
            throw new Error(`Missing value for URL parameter: ${paramName}`);
        }
    });
}

export async function useFetchRoute<T> (
    route: Route,
    urlParams: object | null = null,
    body: object | null = null,
    headers: CustomHeaders = {}
): Promise<T> {
    try {
        const url = replaceUrlParams(route.path, urlParams ?? {});
        const response = await useCustomFetch(url, route.method, {
            ...headers,
            "Leviia-Route": route.path
        }, body, false, route.withCache);

        return response as T;
    } catch (e) {
        console.error(e);
        return null as unknown as T;
    }
}
