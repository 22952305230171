import { type ComputedRef } from "vue";

import type { ChartData, ChartDataTypeKey } from "~/types/chart";

export function formatIntegerUnits (integer: number): string {
    if (integer >= 1000000) {
        return (integer / 1000000).toFixed(1) + " M";
    } else if (integer >= 1000) {
        return (integer / 1000).toFixed(1) + " K";
    }

    return (integer + "").trim();
}

export function getValueAndUnit (value: number): { value: number, unit: string } {
    const base = formatIntegerUnits(value).split(" ");

    return {
        value: Number(base[0]),
        unit: base[1] || ""
    };
}

export function formatSizeUnits<T extends boolean | undefined = undefined> (
    bytes: number,
    unit?: string,
    asNumber?: T,
    withTranslation?: boolean
): T extends true ? number : string {
    const sizeUnits: string[] = [
        "O",
        "KO",
        "MO",
        "GO",
        "TO",
        "PO"
    ];
    let result: string | number;

    if (bytes <= 0) {
        return 0 as T extends true ? number : string;
    }

    if (unit) {
        const index = sizeUnits.indexOf(unit.toUpperCase());
        if (index < 0) {
            throw new Error("Invalid unit");
        }

        result = bytes / Math.pow(1000, index);
    } else if (bytes >= Math.pow(1000, 5)) {
        result = bytes / Math.pow(1000, 5);
        unit = withTranslation ? "unit.pb" : "Po";
    } else if (bytes >= Math.pow(1000, 4)) {
        result = bytes / Math.pow(1000, 4);
        unit = withTranslation ? "unit.tb" : "To";
    } else if (bytes >= Math.pow(1000, 3)) {
        result = bytes / Math.pow(1000, 3);
        unit = withTranslation ? "unit.gb" : "Go";
    } else if (bytes >= Math.pow(1000, 2)) {
        result = bytes / Math.pow(1000, 2);
        unit = withTranslation ? "unit.mb" : "Mo";
    } else if (bytes >= 1000) {
        result = bytes / 1000;
        unit = withTranslation ? "unit.kb" : "Ko";
    } else {
        result = bytes;
        unit = withTranslation ? "unit.b" : "o";
    }

    result = asNumber ? result : fixed2Decimals(result) + " " + unit;

    return result as T extends true ? number : string;
}

export function getSizeObject (
    size: number,
    withTranslation = false
): { quota: number, unit: string } {
    const absSize = Math.abs(size);

    const formattedSize = formatSizeUnits(absSize, undefined, undefined, withTranslation) + "";
    const [ quota, unit ] = formattedSize.split(" ");

    let unitCapitalized: string;

    if (withTranslation) {
        unitCapitalized = unit ?? "unit.kb";
    } else {
        unitCapitalized = unit ? unit.charAt(0).toUpperCase() + unit.slice(1).toLowerCase() : "Ko";
    }

    let quotaValue = 0;

    if (quota) {
        const parsedQuota = Number(quota);
        if (!isNaN(parsedQuota)) {
            quotaValue = parsedQuota;
        }
    }

    if (size < 0) {
        quotaValue *= -1;
    }

    return {
        quota: quotaValue,
        unit: unitCapitalized
    };
}

export function formatPriceUnits (price: number): string {
    return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
        .format(price / 100).replace(/\u202F/g, " ")
        .replaceAll("€", "").trim() + " €";
}

export function toBytes (value: number, unit: string): number {
    switch (unit.toUpperCase()) {
    case "TO":
        return value * Math.pow(1000, 4);
    case "GO":
        return value * Math.pow(1000, 3);
    case "MO":
        return value * Math.pow(1000, 2);
    case "KO":
        return value * 1000;
    case "O":
        return value;
    default:
        throw new Error(`Unknown unit: ${unit}`);
    }
}

// From kib values, return a value and a unit, round value to 2 decimals, and need to be only Go or To
// Exemple: 10995116277760 -> { value: 10, unit: 'To' }
export function fromBytes (bytes: number | ComputedRef<number>): { value: number, unit: string } {
    if (typeof bytes === "object") {
        bytes = bytes.value;
    }

    if (bytes >= Math.pow(1000, 4)) {
        return {
            value: Math.round(bytes / Math.pow(1000, 4) * 100) / 100,
            unit: "To"
        };
    } else {
        const calculatedValue = Math.round(bytes / Math.pow(1000, 3) * 100) / 100;
        return {
            value: isNaN(calculatedValue) ? 0 : calculatedValue,
            unit: "Go"
        };
    }
}

export function isGreaterThan (a: { value: number, unit: string }, b: { value: number, unit: string }): boolean {
    const aValueInBytes = toBytes(a.value, a.unit);
    const bValueInBytes = toBytes(b.value, b.unit);

    return aValueInBytes > bValueInBytes;
}

export function convertToTO (value: number | { quota: number, unit: string }): number {
    let bytes: number;

    if (typeof value === "object") {
        bytes = toBytes(value.quota, value.unit);
    } else {
        bytes = value;
    }

    return bytes / Math.pow(1000, 4);
}

export function convertToToBytes (value: number): number {
    return value * Math.pow(1000, 4);
}

export function fixed2Decimals (value: number | string): string {
    // if string return
    if (typeof value === "string") {
        return value;
    }

    const valueStr = value.toFixed(2);

    // if value is integer return it without decimals
    if (valueStr.endsWith(".00")) {
        return valueStr.slice(0, -3);
    }

    // remove last 0 if value is float
    if (valueStr.endsWith("0")) {
        return valueStr.slice(0, -1);
    }

    // if value is float return it with 2 decimals
    return valueStr;
}

function convertToUnit (bytes: number, unit: string): number {
    if (!unit) {
        unit = "to";
    }

    switch (unit.toLowerCase()) {
    case "po":
    case "unit.pb":
        return bytes / Math.pow(1000, 5);
    case "to":
    case "unit.tb":
        return bytes / Math.pow(1000, 4);
    case "go":
    case "unit.gb":
        return bytes / Math.pow(1000, 3);
    case "mo":
    case "unit.mb":
    case "m":
        return bytes / Math.pow(1000, 2);
    case "ko":
    case "unit.kb":
    case "k":
        return bytes / 1000;
    case "o":
    case "unit.b":
    default:
        return bytes;
    }
}

function getHighestValue (seriesData: ChartData[]): { [key in ChartDataTypeKey]: number } {
    const highestValue: { [key in ChartDataTypeKey]: number } = {
        size: 0,
        object: 0,
        operation: 0
    };

    seriesData.forEach(serie => {
        serie.data.forEach(data => {
            const dataType = serie.dataType;

            if (data !== null && data > highestValue[dataType]) {
                highestValue[dataType] = data;
            }
        });
    });

    return highestValue;
}

function getUnitFromHighestValue (highestValue: {
    [key in ChartDataTypeKey]: number
}): {
    [key in ChartDataTypeKey]: string
} {
    const units: { [key in ChartDataTypeKey]: string } = {
        size: "",
        object: "",
        operation: ""
    };

    for (const key in highestValue) {
        if (key in highestValue) {
            const value = highestValue[key as ChartDataTypeKey];
            if (key === "size") {
                units[key as ChartDataTypeKey] = getSizeObject(value, true).unit;
            } else {
                units[key as ChartDataTypeKey] = getValueAndUnit(value).unit;
            }
        }
    }

    return units;
}

export function getUnitFromSeriesData (seriesData: ChartData[]): ChartData[] {
    // --------- Get highest value from all series data ---------
    const highestValue = getHighestValue(seriesData);

    // --------- Get unit for each data type ---------
    const units = getUnitFromHighestValue(highestValue);
    // --------- Convert all data to the same unit ---------
    return seriesData.map(serie => {
        const unit = units[serie.dataType];

        const newValues = serie.data.map(value => {
            if (value === null) {
                return null;
            }

            let newValue = convertToUnit(value, unit);
            newValue = parseFloat(fixed2Decimals(newValue));

            return newValue;
        });

        return {
            ...serie,
            data: newValues,
            unit
        };
    });
}
