export function getToken (number: number = 16): string {
    return Math.random().toString(36).substring(2, number + 2);
}

export function formatPhoneNumber (phoneNumber: string): string {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    for (let i = 0; i < cleanedPhoneNumber.length; i += 2) {
        formattedPhoneNumber += cleanedPhoneNumber.slice(i, i + 2) + " ";
    }

    return formattedPhoneNumber.trim();
}

export function parseRegion (input: string): { region: string, cluster: number } | null {
    // définition de l'expression régulière pour extraire la région et le cluster
    const regex = /^(\w+)\s(\d+)$/;
    const match = regex.exec(input);

    // si la chaîne de caractères ne correspond pas au format attendu, renvoyer null
    if (!match) {
        return null;
    }

    // extraire les groupes de capture de l'expression régulière
    const regionName = match[1].toLowerCase();
    const cluster = parseInt(match[2]);

    // mapper le nom de la région vers son code ISO 3166-1 alpha-2 correspondant
    const regionCodes: Record<string, string> = {
        france: "fr"
    // ajoutez des entrées pour les autres régions si nécessaire
    };

    if (!regionCodes[regionName]) {
        return null;
    }

    const region = regionCodes[regionName];

    // renvoyer un objet avec les valeurs extraites
    return {
        region,
        cluster
    };
}

export function arraysEqual (a: any[], b: any[]): boolean {
    if (a.length !== b.length) {
        return false;
    }

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function capitalize (str: string): string {
    str = str.trim();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Creates a link object with the provided URL and prefix.
 *
 * @param {string} url - The URL of the link.
 * @param {string|null} prefix - The prefix for the link, optional. Defaults to null.
 * @return {Object} - The link object with key-value pairs for the link and its ending tag.
 */
export function createLink (url: string, prefix: string | null = null): Record<string, string> {
    let key = "link";
    if (prefix) {
        key = `${prefix}_${key}`;
    }

    const target = url.startsWith("http") ? 'target="_blank"' : "";

    return {
        [key]: `<a href="${url}" ${target}>`,
        [`${key}_end`]: "</a>"
    };
}

/**
 * Creates a mailto link for the given email address.
 *
 * @param {string} email - The email address to create the link for.
 * @return {Object} - An object containing the mailto link.
 * @property {string} mail - The generated mailto link.
 */
export function createEmailLink (email: string): { mail: string } {
    const link = createLink(`mailto:${email}`);
    return { mail: `${link.link}${email}${link.link_end}` };
}

/**
 * Creates a simple link.
 *
 * @param {string} url - The URL for the link with http(s)://.
 * @return {Object} An object with the link property formatted as a link like:
 * <a href="https://example.com">example.com</a>
 *
 * Note: the content of the link has no protocol or www prefix, or any / at the end.
 */
export function createSimpleLink (url: string): { link: string } {
    const link = createLink(url);
    const formattedUrl = url.replace(/(^\w+:|^)\/\//, "").replace(/^www\./, "")
        .replace(/\/$/, "");
    return {
        link: `${link.link}${formattedUrl}${link.link_end}`
    };
}
