import {
    createRouteCeph,
    createRouteCeph2,
    createRouteKC,
    createRouteMongo,
    createRouteNextcloud,
    createRouteSIB,
    createRouteStripe,
    type RouteDeclaration
} from "~/routes/core/routes";

export const routesStripe: RouteDeclaration = {
    getCustomer: createRouteStripe("v2/customer/[customerId]", "GET"),
    updateCustomer: createRouteStripe("v2/customer/update/[customerId]", "POST"),

    getInvoices: createRouteStripe("v2/invoices/[customerId]/[startingAfter]", "GET"),
    getUpcomingInvoices: createRouteStripe("v2/upcominginvoices/[customerId]", "GET"),
    getUpcomingInvoicesBySubscription: createRouteStripe("v2/upcominginvoices/subscription/[subscriptionId]", "GET"),

    getSubscriptions: createRouteStripe("v2/subscriptions/[customerId]", "GET"),
    deleteS3Subscription: createRouteStripe("v2/s3-delete/[customerId]", "DELETE"),

    getProduct: createRouteStripe("v2/product/[productId]", "GET"),

    createIntent: createRouteStripe("v2/intent/setup/[customerId]", "POST"),

    getPaymentMethods: createRouteStripe("v2/customer/[customerId]/payment_methods", "GET", false),
    deletePaymentMethod: createRouteStripe("v2/customer/[customerId]/payment_methods/[paymentMethodId]", "DELETE"),

    getDefaultPaymentMethod: createRouteStripe("v2/customer/[customerId]/payment_method/default", "GET", false),
    setAsDefaultPaymentMethod: createRouteStripe("v2/customer/[customerId]/payment_method/[paymentMethodId]", "POST"),

    transformTrialS3: createRouteStripe("v2/s3-transform/[customerId]", "POST"),

    getPrice: createRouteStripe("price/[priceId]", "GET")
};

// Temporary fix for ceph routes, waiting for the new version to be deployed
const USE_CEPH_2: boolean = true;
const CEPH_FN = USE_CEPH_2 ? createRouteCeph2 : createRouteCeph;

export const routesCeph: RouteDeclaration = {
    getIdentifiant: CEPH_FN("identifiant/[region]/[cluster]/[identifiant]", "GET", !USE_CEPH_2),
    createIdentifiant: CEPH_FN("identifiant/[identifiant]", "POST", !USE_CEPH_2),
    deleteIdentifiant: CEPH_FN("identifiant/[region]/[cluster]/[identifiant]", "DELETE", !USE_CEPH_2),
    updateIdentifiant: CEPH_FN("identifiant/[region]/[cluster]/[identifiant]", "PUT", !USE_CEPH_2),

    setIdentifiantQuota: CEPH_FN("identifiant/[region]/[cluster]/[identifiant]/quota", "PUT", !USE_CEPH_2),

    getBucketsOfIdentifiant: CEPH_FN(`bucket${!USE_CEPH_2 ? "s" : ""}/[region]/[cluster]/${USE_CEPH_2 ? "identifiant/" : ""}[identifiant]`, "GET", !USE_CEPH_2),

    getBucket: CEPH_FN("bucket/[region]/[cluster]/[bucket]", "GET", !USE_CEPH_2),
    createBucket: CEPH_FN("bucket/[region]/[cluster]/[identifiant]/[bucket]", "POST", !USE_CEPH_2),
    updateBucket: CEPH_FN("bucket/[region]/[cluster]/[bucket]", "PUT", !USE_CEPH_2),
    deleteBucket: CEPH_FN("bucket/[region]/[cluster]/[bucket]", "DELETE", !USE_CEPH_2),

    verifyBucketExists: CEPH_FN("verify/[bucket]", "GET", !USE_CEPH_2)
};

export const routesKC: RouteDeclaration = {
    getUser: createRouteKC("user/[username]", "GET"),
    createUser: createRouteKC("user", "POST"),

    updatePassword: createRouteKC("password/reset", "GET"),

    getCredentials: createRouteKC("get/credentials", "GET"),
    getCredentialsForUsername: createRouteKC("get/credentials/[username]", "GET"),

    enable2FA: createRouteKC("2fa/enable", "GET"),
    disable2FA: createRouteKC("2fa/disable", "GET")
};

export const routesSIB: RouteDeclaration = {
    cancelSubscription: createRouteSIB("send/unsubscription", "POST"),
    sendPBEquipeCreated: createRouteSIB("send/pocketbase/equipe/create", "POST"),
    claimDeleteBucket: createRouteSIB("send/ask-delete-bucket", "POST")
};

export const routesNextcloud: RouteDeclaration = {
    driveInfos: createRouteNextcloud("drive/infos", "GET"),
    driveProInfos: createRouteNextcloud("drive_pro/infos", "GET")
};

export const routesMongo: RouteDeclaration = {
    get: createRouteMongo("data/[db]/[collection]/[aboS3]", "GET")
};
