import type { DriveProInstance } from "~/types/drive";

export function sortInstances (a: DriveProInstance, b: DriveProInstance): number {
    type StatusOrder = {
        [key in "active" | "trialing" | "unpaid" | "deactivated" | "canceled" | "incomplete"]: number;
    };

    const statusOrder: StatusOrder = {
        active: 1,
        trialing: 2,
        unpaid: 3,
        deactivated: 4,
        canceled: 5,
        incomplete: 6
    };

    // Get the status weight
    const aStatusWeight = statusOrder[a.status as keyof StatusOrder] || 0;
    const bStatusWeight = statusOrder[b.status as keyof StatusOrder] || 0;

    // Compare the status weight
    if (aStatusWeight < bStatusWeight) {
        return -1;
    } else if (aStatusWeight > bStatusWeight) {
        return 1;
    }

    // If the status weight is the same, compare the reference alphabetically
    return a.reference.localeCompare(b.reference);
}
