import type { PBEnums } from "~/types/pb/enums";
import type { StripeRaw } from "~/types/stripe/raw";

import { checkNoSubscription } from "~/composables/notifications";

export function waitForSubscription (subscriptionType: PBEnums.Abonnements): Promise<void> {
    if (__subscription().isFetched(subscriptionType)) {
        void checkNoSubscription(true);
        return Promise.resolve();
    }

    if (__subscription().hasSubscriptionPaid(subscriptionType)) {
        void checkNoSubscription(true);
        return Promise.resolve();
    }

    // wait until __subscription().hasSubscriptionPaid(subscriptionType) is true, after 5s timeout, try every 100ms
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            reject(new Error(`Subscription ${subscriptionType} timed out`));
            void checkNoSubscription(true);
        }, 10000);

        const interval = setInterval(() => {
            if (__subscription().hasSubscriptionPaid(subscriptionType)) {
                clearTimeout(timeout);
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}

export function getProductIDFromItem (item: StripeRaw.Subscription | undefined): string | null {
    if (!item) {
        return null;
    }

    const priceProduct = (item as any).price?.product as string;
    const planProduct = item.plan?.product as string;

    return (priceProduct || planProduct) ?? null;
}

export function getProductID (subscription: StripeRaw.Subscription): string | null {
    if ("items" in subscription) {
        const data = subscription.items?.data[0];
        return getProductIDFromItem(data);
    }

    return null;
}
