export type STDResponse = { message: string } | { error: string };

export enum AboType {
    OS = "object_storage",
    Drive = "drive",
    DrivePro = "drive_pro",
    Options = "options",
    Partner = "partner"
}

export interface Address {
    city?: any
    country: string
    line1?: any
    line2?: any
    postal_code?: any
    state?: any
}

export interface BillingDetails {
    address: Address
    email?: any
    name?: any
    phone?: any
}

export interface Checks {
    address_line1_check?: any
    address_postal_code_check?: any
    cvc_check: string
}

export interface Networks {
    available: string[]
    preferred?: any
}

export interface ThreeDSecureUsage {
    supported: boolean
}

export interface Card {
    brand: string
    checks: Checks
    country: string
    exp_month: number
    exp_year: number
    fingerprint: string
    funding: string
    generated_from?: any
    last4: string
    networks: Networks
    three_d_secure_usage: ThreeDSecureUsage
    wallet?: any
}

export type Metadata = Record<string, string>;

export interface StripePaymentMethod {
    sepa_debit?: any
    card?: Card
    billing_details: BillingDetails
    created: number
    customer: string
    id: string
    livemode: boolean
    metadata: Metadata
    object: string
    type: string
}

export type StripeSubscriptionItem = {
    id: string
    plan: {
        amount: number
        currency: string
        interval: string
        product: string
        active?: boolean
    }
    quantity: number
};

export type StripeSubscription = {
    [key: string]: any
    id: string
    customer: string
    status: string
    current_period_start: number
    current_period_end: number
    trial_start: number
    trial_end: number
    plan: any
    start_date: Date
    items: {
        data: StripeSubscriptionItem[]
    }
    canceled_at: number | null
    name?: string
    bandeau?: string
} | null;

export type InvoiceSettings = {
    custom_fields?: any
    default_payment_method?: any
    footer?: any
    rendering_options?: any
};

export type Customer = {
    address: Address
    balance: number
    created: number
    currency: string
    default_source: string
    delinquent: boolean
    description: string
    discount?: any
    email: string
    id: string
    invoice_prefix: string
    invoice_settings: InvoiceSettings
    livemode: boolean
    metadata: Metadata
    name?: string
    object: string
    phone?: any
    preferred_locales: string[]
    tax_exempt: string
    test_clock?: any
};

export type UpdateInfos = {
    phone: string
    email: string
    enterprise: string
    address: string
    address2: string
    postal_code: string
    city: string
};

export type PaymentType =
  "Visa"
  | "MasterCard"
  | "American Express"
  | "Discover"
  | "Paypal"
  | "Apple Pay"
  | "Google Pay";

export type PaymentInfoCard = {
    id: string
    type: PaymentType
    icon: string
    number: string
    name?: string
    expiration: {
        month: string
        year: string
    }
    expirationFormatted?: string
    cvv?: string
    isDefault: boolean
};

export type PaymentInfoSepa = {
    id: string
    type: "SEPA"
    icon: string
    number: string
    name?: string
    isDefault: boolean
};

export type PaymentInfo = (PaymentInfoCard | PaymentInfoSepa) & { isExpired?: boolean };
