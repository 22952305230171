import { faker } from "@faker-js/faker";
import type { ComposerTranslation } from "vue-i18n";

/**
 * Retrieve a complete URL by prefixing "https://" if the provided URL does not already start with "http".
 *
 * @param {string} urlPro - The URL to be processed.
 * @return {string} - The modified URL.
 */
export function getUrlFrom (urlPro: string): string {
    if (!urlPro) {
        return "";
    }

    if (!urlPro.startsWith("http")) {
        urlPro = "https://" + urlPro;
    }

    return urlPro;
}

/**
 * Formats the given phone number to a standard format.
 *
 * @param {string} phone - The phone number to be formatted.
 * @returns {string} - The phone number in standard format.
 */
export function phoneFormatStandard (phone: string): string {
    if (!phone) {
        return "";
    }

    return phone
        .replace(/^\+33/, "0")
        .padStart(10, "0")
        .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
}

/**
 * Defines the usage of a product.
 *
 * @param {any} product - The product object.
 * @param {ComposerTranslation} t - The translation object.
 * @returns {string} - The usage description.
 *
 * @example
 * defineUsage({ metadata: { space_allowed: 1000 } }, t); // "1 To / 1000 To"
 */
export function defineUsage (product: any, t: ComposerTranslation): string {
    const usageRaw = faker.number.float({
        min: 0,
        max: 3,
        multipleOf: 0.0001
    }); // en To

    const spaceAllowed = product.metadata.space_allowed ?? 0;
    const isGo = usageRaw < 1;

    // Si usage en Go, arrondir, sinon montrer une décimale
    const formattedUsage = isGo ? Math.round(usageRaw * 1000) : usageRaw.toFixed(1);

    const usageStr = `${formattedUsage} ${isGo ? t("unit.gb") : t("unit.tb")}`;

    const sizeMax = spaceAllowed > 0 ? `/ ${spaceAllowed} To` : `(${t("label.unlimited")})`;

    return `${usageStr} ${sizeMax}`;
}

/**
 * Returns the region and cluster number from a given cluster string.
 *
 * @param {string} clusterString - The cluster string in the format "REGIONCLUSTER".
 * @returns {Object} An object containing the region and cluster number.
 * @throws {Error} Throws an error if the cluster string is invalid.
 *
 * @example
 * getClusterRegionFromPBCluster("fr2"); // { region: "fr", cluster: 2 }
 *
 * @example
 * getClusterRegionFromPBCluster("idf57"); // { region: "idf", cluster: 57 }
 */
export function getClusterRegionFromPBCluster (clusterString: string): { region: string, cluster: number } {
    if (clusterString.trim().length === 0) {
        // return {
        //     region: "fr",
        //     cluster: 2
        // };
        throw new Error("Cluster string cannot be empty");
    }

    const match = /(.*)(\d)$/.exec(clusterString);
    if (!match) {
        throw new Error("Invalid string format");
    }

    return {
        region: match[1],
        cluster: Number(match[2])
    };
}
