import type { Stripe, StripeElements } from "@stripe/stripe-js";
import { defineStore } from "pinia";

import { __pbUser } from "~/stores/pb-user.store";
import { __subscription } from "~/stores/subscription.store";
import { __user } from "~/stores/user.store";

import { useFetchRoute } from "~/utils/fetch";
import { getEnv } from "~/utils/polyfill";

import { routesStripe } from "~/routes/declarations";

import type { StripeInvoices, StripeUserInvoice } from "~/types/invoices";
import type {
    Customer,
    PaymentInfo,
    PaymentInfoCard,
    PaymentInfoSepa,
    STDResponse,
    StripePaymentMethod,
    StripeSubscription,
    UpdateInfos
} from "~/types/stripe";
import { AboType } from "~/types/stripe";

import { formatMonthYear } from "~/composables/date";

const config = getEnv();

export const __stripe = defineStore(
    "stripeStore",
    {
        state: (): {
            loaded: boolean
            user: Customer | null
            subscriptions: StripeSubscription[]
            products: Record<string, any>
            elements: StripeElements | null
            customerId: string
            parentCustomerId: string
        } => ({
            loaded: false,
            user: null,
            subscriptions: [],
            products: [],
            elements: null,
            customerId: "",
            parentCustomerId: ""
        }),
        getters: {
            isLoaded: state => state.loaded,
            getUser: state => state.user,
            getCustomerId: state => state.customerId,
            getParentCustomerId: state => state.parentCustomerId
        },
        actions: {
            setCustomerId (customerId: string) {
                if (customerId === this.customerId) {
                    return;
                }

                this.customerId = customerId;

                void this.getInfos(customerId);
                void __subscription().checkSubscriptions(this.customerId);
            },
            setParentCustomerId (customerId: string) {
                if (customerId === this.parentCustomerId) {
                    return;
                }

                this.parentCustomerId = customerId;
                void __subscription().checkSubscriptions(this.parentCustomerId, true);
            },
            async getInfos (currentCustomerId?: string | undefined) {
                if (!currentCustomerId) {
                    if (!__pbUser().isInitialized) {
                        await __pbUser().init();
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                    currentCustomerId = __stripe().getCustomerId || __stripe().getParentCustomerId;
                }

                if (!this.user && currentCustomerId) {
                    this.user = await useFetchRoute<Customer>(
                        routesStripe.getCustomer,
                        { customerId: currentCustomerId }
                    );

                    if (this.user) {
                        this.loaded = true;
                        __user().setPhone(this.user.phone ?? "");
                    }
                }

                return this.user as Customer;
            },
            async updateInfos (infos: Partial<UpdateInfos>) {
                this.user = await useFetchRoute<Customer>(
                    routesStripe.updateCustomer, { customerId: __stripe().getCustomerId },
                    {
                        phone: infos.phone?.replaceAll(" ", "").replace(/^0/, "") ?? this.user?.phone,
                        email: infos.email ?? this.user?.email,
                        enterprise: infos.enterprise ?? this.user?.name,
                        line1: infos.address ?? this.user?.address?.line1,
                        line2: infos.address2 ?? this.user?.address?.line2,
                        postal_code: infos.postal_code ?? this.user?.address?.postal_code,
                        city: infos.city ?? this.user?.address?.city,
                        name: this.user?.name ?? (__user().user.firstName + " " + __user().user.familyName)
                    }
                );
            },
            async setDefaultPaymentMethod (paymentMethodId: string) {
                return await useFetchRoute<any>(routesStripe.setAsDefaultPaymentMethod, {
                    customerId: __stripe().getCustomerId,
                    paymentMethodId
                });
            },
            async getDefaultPaymentMethod () {
                return await useFetchRoute<any>(
                    routesStripe.getDefaultPaymentMethod,
                    { customerId: __stripe().getCustomerId }
                );
            },
            async getSubscriptions (customerId?: string | undefined) {
                if (!customerId) {
                    customerId = __stripe().getCustomerId || __stripe().getParentCustomerId;
                }

                if (this.subscriptions.length <= 0) {
                    await callOnce("fetchSubscriptions", async () => {
                        const res
                            = await useFetchRoute<{
                            data: StripeSubscription[]
                        }>(routesStripe.getSubscriptions, { customerId });
                        if (!res?.data) {
                            return;
                        }

                        this.subscriptions = res?.data;
                    });
                }

                return this.subscriptions;
            },
            async transformTrialToPaid () {
                const res = await useFetchRoute<STDResponse>(
                    routesStripe.transformTrialS3,
                    { customerId: __stripe().getCustomerId }
                );

                return "message" in res;
            },
            async deleteS3Subscription () {
                const res = await useFetchRoute<STDResponse>(
                    routesStripe.deleteS3Subscription,
                    { customerId: __stripe().getCustomerId },
                    { username: __user().user.username }
                );
                return "message" in res;
            },
            async getProduct (productId: string): Promise<any> {
                if (!this.products) {
                    this.products = {};
                }

                if (!this.products[productId]) {
                    const prod = await useFetchRoute(routesStripe.getProduct, { productId })
                        .then(data => {
                            this.products[productId] = data;
                            return data;
                        });

                    if (prod) {
                        this.products[productId] = prod;
                    }
                }

                return Promise.resolve(this.products[productId]);
            },
            getProds: function (
                subs: StripeSubscription[],
                subscriptionType: AboType | AboType.OS | AboType.Drive | AboType.DrivePro | AboType.Partner
            ) {
                return subs.map(async (sub: any) => {
                    if (sub) {
                        const productIds = sub.items.data.map(({ plan }: { plan: any }) => plan.product);
                        const products: any[] = [];

                        for (const productId of productIds) {
                            if (productId) {
                                const data = await this.getProduct(productId);
                                products.push(data);
                            }
                        }

                        if (subscriptionType === AboType.Options) {
                            return products.filter(product => product.metadata["is_option"] === "true") ?? [];
                        }

                        return products.filter(product => product.metadata.abonnement === subscriptionType) ?? [];
                    }

                    return [];
                }).flat();
            },
            async getProducts (subscriptionType: AboType, full = false): Promise<(any)[]> {
                const subs = await this.getSubscriptions();
                if (subs) {
                    const prods = this.getProds(subs, subscriptionType);

                    if (full) {
                        return (await Promise.all(prods)).flat();
                    }

                    // return only product id list
                    return (await Promise.all(prods)).flat().map((product: any) => product.id);
                }

                return [];
            },
            getPlan (productId: string, name = "", icon: string | null = null): StripeSubscription | null {
                if (!this.subscriptions || this.subscriptions.length <= 0) {
                    return null;
                }

                const sub: StripeSubscription | undefined
                    = this.subscriptions.find((sub: any) => sub?.plan?.product === productId);

                if (sub) {
                    sub.name = name;
                    sub.icon = icon;

                    if (sub.status === "trialing") {
                        sub.bandeau = "Essai";
                    }
                }

                return sub ?? null;
            },
            async getInvoices (startingAfter = "0") {
                return await useFetchRoute<StripeInvoices>(routesStripe.getInvoices, {
                    customerId: __stripe().getCustomerId,
                    startingAfter
                });
            },
            async fetchInvoices () {
                try {
                    const invoices = [] as StripeUserInvoice[];
                    let startingAfter = "0";
                    let hasMore = true;
                    let countSecurity = 0;
                    while (hasMore) {
                        countSecurity++;
                        const invoiceFetched = await this.getInvoices(startingAfter);
                        if (invoiceFetched) {
                            hasMore = invoiceFetched.has_more ?? false;
                            startingAfter = invoiceFetched.data[invoiceFetched.data.length - 1].id;
                            invoices.push(...invoiceFetched.data);
                        } else {
                            hasMore = false;
                        }

                        if (countSecurity > 50) {
                            hasMore = false;
                        }
                    }

                    return invoices.filter(invoice => {
                        return invoice.status !== "draft"
                            && invoice.status !== "void"
                            && (invoice.amount_due > 0
                                || invoice.amount_paid > 0);
                    });
                } catch (e) {
                    return [];
                }
            },
            async createIntents (): Promise<{
                client_secret: string
            }> {
                return await useFetchRoute<{
                    client_secret: string
                }>(routesStripe.createIntent, { customerId: __stripe().getCustomerId });
            },
            async initializeStripeIntents (stripe: Stripe) {
                const clientSecret: any = await this.createIntents();
                this.elements = stripe.elements({
                    appearance: {
                        theme: "flat",
                        variables: {
                            colorPrimary: "#00C599",
                            colorBackground: "#f4f9f7",
                            fontFamily: "Inter, sans-serif",
                            spacingUnit: ".2rem",
                            borderRadius: ".5rem"
                        }
                    },
                    clientSecret: clientSecret.client_secret,
                    fonts: [
                        {
                            cssSrc: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
                        }
                    ],
                    locale: "fr"
                });

                const paymentElementOptions = {
                    layout: {
                        type: "accordion",
                        defaultCollapsed: false,
                        radios: false,
                        spacedAccordionItems: false
                    }
                };

                // @ts-ignore
                const paymentElement = this.elements.create("payment", paymentElementOptions);
                paymentElement.mount("#payment-element");
            },
            async handleSubmit (stripe: Stripe) {
                if (this.elements && __stripe().getCustomerId) {
                    const paymentMethods = await this.getPaymentMethods();
                    localStorage.setItem("paymentMethods", JSON.stringify(paymentMethods));

                    await stripe.confirmSetup({
                        elements: this.elements as StripeElements,
                        confirmParams: {
                            return_url: config.APP_URL + "user/billing/payment-methods"
                        }
                    });
                }
            },
            async getPaymentMethods () {
                if (!this.getCustomerId) {
                    return [];
                }
                const defaultMethod = (await this.getDefaultPaymentMethod())["payment_method_id"];
                const data = await useFetchRoute<{
                    data: StripePaymentMethod[]
                }>(routesStripe.getPaymentMethods, { customerId: __stripe().getCustomerId })
                    .then(response => response.data);

                if (data) {
                    return data.map(payment => {
                        if ("card" in payment && payment.card) {
                            return {
                                id: payment.id,
                                type: payment.card.brand,
                                icon: `payment/${payment.card.brand.toLowerCase()}`,
                                number: payment.card.last4,
                                name: payment.billing_details.name,
                                expiration: {
                                    month: payment.card.exp_month.toString().padStart(2, "0"),
                                    year: payment.card.exp_year.toString()
                                },
                                expirationFormatted: formatMonthYear(payment.card.exp_month, payment.card.exp_year),
                                cvv: "123",
                                isDefault: payment.id === defaultMethod
                            } as PaymentInfoCard;
                        } else if ("sepa_debit" in payment && payment.sepa_debit) {
                            return {
                                id: payment.id,
                                type: "SEPA",
                                icon: "payment/sepa",
                                number: payment.sepa_debit.last4,
                                name: payment.billing_details.name,
                                isDefault: payment.id === defaultMethod
                            } as PaymentInfoSepa;
                        }

                        return null;
                    }) as PaymentInfo[];
                }

                return [];
            },
            async deletePaymentMethod (payment: PaymentInfo) {
                const methods = await this.getPaymentMethods();
                let newDefault: PaymentInfo | null = null;

                if (payment.isDefault) {
                    newDefault = methods.find((m: { id: string }) => m?.id !== payment.id) ?? null;
                }

                const r = await useFetchRoute<{
                    data: StripePaymentMethod[]
                }>(routesStripe.deletePaymentMethod, {
                    customerId: __stripe().getCustomerId,
                    paymentMethodId: payment.id
                });

                if (newDefault) {
                    if (__stripe().getCustomerId) {
                        await this.setDefaultPaymentMethod(newDefault.id);
                    }
                }

                return r;
            },
            addSubscription (subscription: StripeSubscription) {
                this.subscriptions.push(subscription);
            },
            async getPrice (priceId: string) {
                return await useFetchRoute<any>(routesStripe.getPrice, { priceId });
            },
            isSubscriptionMetered (subscription: StripeSubscription): boolean {
                if (!subscription?.plan) {
                    return false;
                }

                return subscription.plan.usage_type === "metered";
            }
        }
    }
);
