import { __alert } from "~/stores/alert.store";

import { getEnv } from "~/utils/polyfill";

import type { Wrapper } from "~/types/api";

const activeRoute = ref("/");

export const useActiveRoute = computed(() => {
    __alert().checkNotifs();
    return activeRoute.value;
});

export function setActiveRoute (route: string): void {
    activeRoute.value = route;
}

export function getBaseURL (wrapper: Wrapper): string {
    const config = getEnv();

    let wrapperUrl;
    switch (wrapper) {
    case "stripe":
        wrapperUrl = config.WRAPPER_STRIPE;
        break;
    case "keycloack":
        wrapperUrl = config.WRAPPER_KEYCLOACK;
        break;
    case "ceph":
        wrapperUrl = config.WRAPPER_CEPH;
        break;
    case "ceph2":
        wrapperUrl = config.WRAPPER_CEPH_V2;
        break;
    case "sib":
        wrapperUrl = config.WRAPPER_SIB;
        break;
    case "nextcloud":
        wrapperUrl = config.WRAPPER_NEXTCLOUD;
        break;
    case "mongo":
        wrapperUrl = config.WRAPPER_MONGO;
        break;
    default:
        throw new Error(`Unsupported wrapper: ${wrapper}`);
    }

    return (wrapperUrl ?? "").replace(/\/$/, "");
}
