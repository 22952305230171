export namespace PBEnums {
    export enum Abonnements {
        S3 = "s3",
        Partner = "distributeur",
        Drive = "drive",
        DrivePro = "drive_pro"
    }

    export enum Roles {
        Owner = "owner",
        Admin = "admin",
        Invoice = "invoice",
        None = "none"
    }
}
