export namespace StripeEnums {
    export enum SubscriptionStatus {
        Incomplete = "incomplete",
        IncompleteExpired = "incomplete_expired",
        Trialing = "trialing",
        Active = "active",
        PastDue = "past_due",
        Unpaid = "unpaid",
        Canceled = "canceled",

        None = "none"
    }
}
