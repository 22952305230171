import { defineStore } from "pinia";

import { __identifier } from "~/stores/identifier.store";

import { routesCeph } from "~/routes/declarations";

import type { Bucket, CephBucket, GetBucketsOptions } from "~/types/bucket";
import { BucketLockingMode } from "~/types/bucket";
import type { Identifier } from "~/types/identifiants";

export const __bucket = defineStore(
    "bucketStore",
    {
        state: (): {
            loaded: boolean
            localBucketsNames: string[]
            localBuckets: Bucket[]
            currentBuckets: Bucket[]
            currentPage: number
        } => ({
            loaded: false,
            localBucketsNames: [],
            localBuckets: [],
            currentBuckets: [],
            currentPage: 1
        }),
        getters: {
            isLoading: state => !state.loaded,
            getCurrentBuckets: state => state.currentBuckets,
            getCurrentBucketsLength: state => state.currentBuckets.length,
            getCurrentPage: state => state.currentPage,
            getTotalObjects: state => state.localBuckets.reduce((total: any, bucket: {
                objects: any
            }) => total + bucket.objects, 0),
            getBucketsLength: state => state.localBucketsNames.length
        },
        actions: {
            setLoadingState (state: boolean) {
                this.loaded = !state;
            },
            setCurrentPage (page: number) {
                this.currentPage = page;
            },
            setCurrentBuckets (buckets: Bucket[]) {
                this.currentBuckets = buckets;
            },
            addToLocalBucketList (bucket: string) {
                if (!this.localBucketsNames.includes(bucket)) {
                    this.localBucketsNames.push(bucket);
                }
            },
            async getOnfulfilled (
                identifier: Identifier,
                cephBucket: CephBucket
            ): Promise<void> {
                let size = 0;
                let objects = 0;
                if (cephBucket.usage) {
                    Object.keys(cephBucket.usage).forEach(key => {
                        size += cephBucket.usage[key].size_actual;
                        objects += cephBucket.usage[key].num_objects;
                    });
                }

                await this.addLocalBucket({
                    name: cephBucket.bucket,
                    created: new Date(cephBucket.creation_time),
                    id: cephBucket.owner,
                    idName: identifier.description,
                    size,
                    objects,
                    versioning: cephBucket?.versioning ? cephBucket.versioning === "Enabled" : false,
                    locking: cephBucket?.lock_enabled ?? false,
                    lockingMode: cephBucket?.lock_mode ?? BucketLockingMode.Manuel,
                    lockingDuration: Math.max(cephBucket?.lock_retention_period_days ?? 1),
                    twoFactorAuth: cephBucket?.mfa_delete === "Enabled"
                });
            },
            getDetailedBucketsFromWrapper (buckets: string[]) {
                return new Promise<void>(resolve => {
                    this.loaded = false;
                    const bucketsAmount = buckets.length;
                    let bucketsFetched = 0;
                    if (bucketsAmount === 0) {
                        this.loaded = true;
                        resolve();
                    }

                    for (const bucket of buckets) {
                        const identifier = __identifier().getIdentifierOfBucket(bucket);
                        if (!identifier) {
                            continue;
                        }

                        if (!identifier.active) {
                            this.addLocalBucket({
                                name: bucket,
                                created: new Date(),
                                id: identifier.id,
                                idName: identifier.description,
                                size: 0,
                                objects: 0,
                                versioning: false,
                                locking: false,
                                lockingMode: BucketLockingMode.Compliance,
                                lockingDuration: 0,
                                twoFactorAuth: false,
                                notFound: true
                            }).then(() => {
                                bucketsFetched++;
                                if (bucketsFetched >= bucketsAmount) {
                                    this.loaded = true;
                                    resolve();
                                }
                            });

                            continue;
                        }
                        useFetchRoute<CephBucket>(routesCeph.getBucket, {
                            bucket,
                            region: identifier.region,
                            cluster: identifier.cluster
                        }).then(cephBucket => {
                            this.getOnfulfilled(identifier, cephBucket).then(() => {
                                bucketsFetched++;
                                if (bucketsFetched >= bucketsAmount) {
                                    this.loaded = true;
                                    resolve();
                                }
                            });
                        });
                    }
                });
            },
            getBucket (name: string): Promise<Bucket | null> {
                return new Promise<Bucket>(resolve => {
                    __identifier().getIdentifiers().then(() => {
                        const local = this.localBuckets.find((b: { name: string }) => b.name === name) as Bucket;
                        if (local) {
                            resolve(local);
                        }

                        this.fetchMissingBuckets([ name ]).then(() => {
                            resolve(this.localBuckets.find((b: { name: string }) => b.name === name) as Bucket ?? null);
                        });
                    });
                });
            },
            sortBuckets () {
                this.localBuckets
                    .sort((a: { name: string }, b: { name: any }) => (a.name && b.name)
                        ? a.name.localeCompare(b.name)
                        : 0);
            },
            extracIdentifier: function (
                options: GetBucketsOptions | undefined,
                resolve: (value: (PromiseLike<Bucket[]> | Bucket[])) => void
            ) {
                if (options?.page && options.limit) {
                    const limit = parseInt((options.limit ?? "0") as string);
                    const start = (options.page - 1) * limit;
                    const end = start + limit;
                    const bucketForPage = (this.localBucketsNames
                        .toSorted((a: string, b: any) => (a && b)
                            ? a.localeCompare(b)
                            : 0))
                        .slice(start, end);

                    this.fetchMissingBuckets(bucketForPage).then(() => {
                        resolve(this.localBuckets.filter((b: { name: any }) => bucketForPage.includes(b.name)));
                    });
                } else {
                    this.fetchMissingBuckets(this.localBucketsNames).then(() => {
                        resolve(this.localBuckets);
                    });
                }
            },
            getBuckets (options?: GetBucketsOptions): Promise<Bucket[]> {
                if (!options) {
                    options = {
                        limit: 8,
                        page: 1
                    };
                }

                return new Promise<Bucket[]>(resolve => {
                    __identifier().getIdentifiers().then(() => {
                        if (this.localBucketsNames.length === 0) {
                            this.loaded = true;
                            resolve([]);
                        }

                        this.extracIdentifier(options, resolve);
                    });
                });
            },
            fetchMissingBuckets (bucketsToFetch: string[]): Promise<void> {
                return new Promise<void>(resolve => {
                    const toFetchBucket: string[] = [];
                    bucketsToFetch.forEach(bucket => {
                        if (!this.localBuckets.find((b: { name: string }) => b.name === bucket)) {
                            toFetchBucket.push(bucket);
                        }
                    });
                    if (toFetchBucket.length > 0) {
                        this.getDetailedBucketsFromWrapper(toFetchBucket).then(() => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                });
            },
            getBucketsPages (limit: number): number {
                return Math.ceil(this.localBucketsNames.length / limit);
            },
            editBucket (name: string, bucket: Bucket) {
                const index = this.localBuckets.findIndex((b: { name: string }) => b.name === name);
                const oldBucket = this.localBuckets[index];
                const newBucket: Bucket = {
                    name: oldBucket.name,
                    created: oldBucket.created,
                    id: bucket.id,
                    idName: bucket.idName,
                    size: oldBucket.size,
                    objects: oldBucket.objects,
                    versioning: bucket.versioning,
                    locking: bucket.locking,
                    lockingMode: bucket.lockingMode,
                    lockingDuration: bucket.lockingDuration,
                    twoFactorAuth: bucket.twoFactorAuth
                };
                // delete from local cache
                this.localBuckets.splice(index, 1);

                this.addLocalBucket(newBucket).then(() => {
                    const body: Record<string, any> = {};

                    if (newBucket.versioning !== oldBucket.versioning) {
                        body["versioning_state"] = newBucket.versioning ? "Enabled" : "Suspended";
                    }

                    if (oldBucket.locking) {
                        if (newBucket.lockingMode !== oldBucket.lockingMode) {
                            if (newBucket.lockingMode === BucketLockingMode.Manuel) {
                                body["lock_mode"] = "None";
                            } else {
                                body["lock_mode"] = newBucket.lockingMode.toString().toUpperCase();
                            }
                        }

                        if (newBucket.lockingDuration !== oldBucket.lockingDuration) {
                            body["lock_retention_period_days"] = Math.max(newBucket.lockingDuration, 1);
                        }
                    }

                    if (newBucket.id !== oldBucket.id) {
                        body.uid = newBucket.id;
                    }

                    const identifier = __identifier().getIdentifierOfBucket(oldBucket.name);

                    useFetchRoute(routesCeph.updateBucket, {
                        bucket: oldBucket.name,
                        region: identifier?.region,
                        cluster: identifier?.cluster
                    }, body).then(r => r);
                });
            },
            removeFromLocalBuckets (bucketName: string) {
                this.localBuckets = this.localBuckets.filter((b: { name: string }) => b.name !== bucketName);
            },
            async addBucket (bucket: Bucket, identifier: Identifier | undefined = undefined): Promise<void> {
                const body: Record<string, any> = {};
                if (bucket.versioning) {
                    body["versioning_state"] = bucket.versioning ? "Enabled" : "Suspended";

                    if (bucket.locking) {
                        body["lock_enabled"] = bucket.locking.toString();
                        if (bucket.lockingMode === BucketLockingMode.Manuel) {
                            body["lock_mode"] = "None";
                        } else {
                            body["lock_mode"] = bucket.lockingMode.toString().toUpperCase();
                        }
                        body["lock_retention_period_days"] = Math.max(bucket.lockingDuration, 1);
                    }
                }

                if (!identifier) {
                    identifier = __identifier().getIdentifierById(bucket.id);
                    if (!identifier) {
                        throw new Error("No identifier found for bucket");
                    }
                }

                await useFetchRoute(routesCeph.createBucket, {
                    bucket: bucket.name,
                    identifiant: bucket.id,
                    region: identifier?.region ?? "",
                    cluster: identifier?.cluster ?? ""
                }, body);
                await this.addLocalBucket(bucket);
            },
            async addLocalBucket (bucket: Bucket): Promise<void> {
                if (this.localBuckets.find((b: { name: string }) => b.name === bucket.name)) {
                    return;
                }

                if (bucket.lockingMode === "NULL") {
                    bucket.lockingDuration = 0;
                }

                this.localBuckets.push(bucket);
                this.addToLocalBucketList(bucket.name);
                try {
                    this.sortBuckets();
                } catch (e) {
                    console.error(e);
                }
                await __identifier().addBucketToIdenfifier(bucket.id, bucket.name);
                this.currentBuckets = [];
            },
            canDelete: async function (name: string): Promise<boolean> {
                const b = await this.getBucket(name);
                if (!b) {
                    return false;
                }
                return b.objects === 0;
            },
            async deleteBucket (name: string): Promise<void> {
                if (!await this.canDelete(name)) {
                    throw new Error("Bucket not empty");
                }

                const identifier = __identifier().getIdentifierOfBucket(name);
                this.loaded = false;
                const index = this.localBuckets.findIndex((b: { name: string }) => b.name === name);
                this.localBuckets.splice(index, 1);
                this.localBucketsNames.splice(this.localBucketsNames.findIndex((b: string) => b === name), 1);
                await useFetchRoute(routesCeph.deleteBucket, {
                    bucket: name,
                    region: identifier?.region,
                    cluster: identifier?.cluster
                });
                await new Promise(resolve => setTimeout(resolve, 500)); // wait for ceph to delete bucket
                __identifier().removeBucketToAllIdenfifier(name);
                this.currentBuckets = [];
                this.loaded = true;
            },
            async isBucketExist (name: string): Promise<boolean> {
                const bucketRequest = await useFetchRoute<{ exists: boolean }>(routesCeph.verifyBucketExists, {
                    bucket: name
                });

                return bucketRequest?.exists ?? false;
            },
            async refreshBuckets (options?: GetBucketsOptions) {
                this.localBuckets = [];
                await this.getBuckets({ ...options });
            },
            getLocalBucket (name: string): Bucket | undefined {
                return this.localBuckets.find((b: { name: string }) => b.name === name);
            },
            async fetchBucket (name: string): Promise<Bucket | null> {
                let identifier = __identifier().getIdentifierOfBucket(name);
                if (!identifier) {
                    await __identifier().getIdentifiersFromPB();
                    identifier = __identifier().getIdentifierOfBucket(name);
                }

                if (!identifier) {
                    return null;
                }

                const bucket = await useFetchRoute<CephBucket>(routesCeph.getBucket, {
                    bucket: name,
                    region: identifier.region,
                    cluster: identifier.cluster
                });

                if (!bucket) {
                    return null;
                }

                let size = 0;
                let objects = 0;
                if (bucket.usage) {
                    Object.keys(bucket.usage).forEach(key => {
                        size += bucket.usage[key].size_actual;
                        objects += bucket.usage[key].num_objects;
                    });
                }

                const newBucket: Bucket = {
                    name: bucket.bucket,
                    created: new Date(bucket.creation_time),
                    id: bucket.owner,
                    idName: identifier.description,
                    size,
                    objects,
                    versioning: bucket?.versioning ? bucket.versioning === "Enabled" : false,
                    locking: bucket?.lock_enabled ?? false,
                    lockingMode: bucket?.lock_mode ?? BucketLockingMode.Manuel,
                    lockingDuration: Math.max(bucket?.lock_retention_period_days ?? 1),
                    twoFactorAuth: bucket?.mfa_delete === "Enabled"
                };

                await this.addLocalBucket(newBucket);
                return newBucket;
            },
            getLocking (bucket: string): boolean {
                const local = this.localBuckets.find((b: { name: string }) => b.name === bucket);
                return local?.locking ?? false;
            }
        }
    }
);
