import type { Method, Wrapper } from "~/types/api";

import { getBaseURL } from "~/composables/routes";

export type Route = {
    path: string
    method: Method
    withCache: boolean
};

export function createRoute (
    wrapper: Wrapper,
    path: string,
    method: Method,
    withCache = true
): Route {
    return {
        path: `${getBaseURL(wrapper)}/${path}`,
        method,
        withCache
    };
}

export function createRouteStripe (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("stripe", path, method, withCache);
}

export function createRouteKC (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("keycloack", path, method, withCache);
}

export function createRouteCeph (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("ceph", path, method, withCache);
}

export function createRouteCeph2 (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("ceph2", path, method, withCache);
}

export function createRouteSIB (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("sib", path, method, withCache);
}

export function createRouteNextcloud (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("nextcloud", path, method, withCache);
}

export function createRouteMongo (
    path: string,
    method: Method,
    withCache = true
): Route {
    return createRoute("mongo", path, method, withCache);
}

export type RouteDeclaration = Record<string, Route>;
