import { defineStore } from "pinia";

import { __alert } from "~/stores/alert.store";
import { __pbUser } from "~/stores/pb-user.store";
import { __stripe } from "~/stores/stripe.store";

import { routesStripe } from "~/routes/declarations";

import type { PBCollection } from "~/types/pb/collections";
import { PBEnums } from "~/types/pb/enums";
import type { StripeSubscription } from "~/types/stripe";
import { StripeEnums } from "~/types/stripe/enums";
import type { StripeRaw } from "~/types/stripe/raw";

import { getProductID, getProductIDFromItem } from "~/composables/subscription";
import SubscriptionStatus = StripeEnums.SubscriptionStatus;

export const keycloakIdsOfSubscriptions: {
    subscription: PBEnums.Abonnements;
    allowedValues: string[];
}[] = [
    {
        subscription: PBEnums.Abonnements.S3,
        allowedValues: [ "s3", "object-storage" ]
    },
    {
        subscription: PBEnums.Abonnements.Partner,
        allowedValues: [ "partner", "distributeur" ]
    },
    {
        subscription: PBEnums.Abonnements.Drive,
        allowedValues: [
            "drive",
            "solo",
            "perso",
            "equipe",
            "tribu",
            "expert"
        ]
    },
    {
        subscription: PBEnums.Abonnements.DrivePro,
        allowedValues: [ "pro", "drive_pro" ]
    }
];

export const __subscription = defineStore("subscriptionStore", {
    state: (): {
        [key in PBEnums.Abonnements]: {
            fetched: boolean;
            quota?: number;
            size?: number;
            objects: number;
            parentHasPaid?: boolean;
            quotaReached?: boolean;
            description?: string;
            startedAt?: Date;
            interval?: "label.monthly" | "label.yearly";
            status: StripeEnums.SubscriptionStatus;
            startsCycleDate: Date;
            trial: {
                startedAt: Date;
                until: Date;
                quota: number;
            } | null;
        };
    } => ({
        [PBEnums.Abonnements.S3]: {
            fetched: false,
            status: StripeEnums.SubscriptionStatus.None,
            objects: 0,
            parentHasPaid: false,
            trial: null,
            startsCycleDate: new Date()
        },
        [PBEnums.Abonnements.Partner]: {
            fetched: false,
            status: StripeEnums.SubscriptionStatus.None,
            objects: 0,
            trial: null,
            startsCycleDate: new Date()
        },
        [PBEnums.Abonnements.Drive]: {
            fetched: false,
            status: StripeEnums.SubscriptionStatus.None,
            objects: 0,
            trial: null,
            startsCycleDate: new Date()
        },
        [PBEnums.Abonnements.DrivePro]: {
            fetched: false,
            status: StripeEnums.SubscriptionStatus.None,
            objects: 0,
            trial: null,
            startsCycleDate: new Date()
        }
    }),
    getters: {
        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ HAS PAID ]-------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        hasSubscriptionPaid:
            state => (subscription: PBEnums.Abonnements): boolean => {
                const hasAccess: StripeEnums.SubscriptionStatus[] = [
                    StripeEnums.SubscriptionStatus.Trialing,
                    StripeEnums.SubscriptionStatus.Active,
                    StripeEnums.SubscriptionStatus.PastDue,
                    StripeEnums.SubscriptionStatus.Unpaid,
                    StripeEnums.SubscriptionStatus.Incomplete,
                    StripeEnums.SubscriptionStatus.IncompleteExpired
                ];

                return hasAccess.includes(state[subscription].status);
            },
        hasSubscriptionPaidActive:
            state => (subscription: PBEnums.Abonnements): boolean => {
                const hasAccess: StripeEnums.SubscriptionStatus[] = [
                    StripeEnums.SubscriptionStatus.Trialing,
                    StripeEnums.SubscriptionStatus.Active,
                    StripeEnums.SubscriptionStatus.PastDue
                ];
                return hasAccess.includes(state[subscription]?.status) ?? false;
            },
        checkStateIsActive:
            () => (subscriptionStatus: StripeEnums.SubscriptionStatus): boolean => {
                const hasAccess: StripeEnums.SubscriptionStatus[] = [
                    StripeEnums.SubscriptionStatus.Trialing,
                    StripeEnums.SubscriptionStatus.Active,
                    StripeEnums.SubscriptionStatus.PastDue
                ];
                return hasAccess.includes(subscriptionStatus) ?? false;
            },
        hasSubscriptionStatus:
            state => (
                subscription: PBEnums.Abonnements,
                status: StripeEnums.SubscriptionStatus
            ): boolean => {
                return state[subscription].status === status;
            },
        hasAnySubscriptionStatus:
            state => (status: StripeEnums.SubscriptionStatus): boolean => {
                [
                    PBEnums.Abonnements.S3,
                    PBEnums.Abonnements.Partner,
                    PBEnums.Abonnements.Drive,
                    PBEnums.Abonnements.DrivePro
                ].forEach((subscription: PBEnums.Abonnements) => {
                    if (state[subscription].status === status) {
                        return true;
                    }
                });

                return false;
            },
        isSubscriptionStatus:
            state => (
                subscription: PBEnums.Abonnements,
                status: StripeEnums.SubscriptionStatus
            ): boolean => {
                return state[subscription].status === status;
            },
        isSubscriptionAnyOfStatus:
            state => (
                subscription: PBEnums.Abonnements,
                status: StripeEnums.SubscriptionStatus[]
            ): boolean => {
                return status.includes(state[subscription].status);
            },
        getSubscriptionsByStatus:
            state => (status: StripeEnums.SubscriptionStatus): PBEnums.Abonnements[] => {
                const subscriptions: PBEnums.Abonnements[] = [];

                [
                    PBEnums.Abonnements.S3,
                    PBEnums.Abonnements.Partner,
                    PBEnums.Abonnements.Drive,
                    PBEnums.Abonnements.DrivePro
                ].forEach((subscription: PBEnums.Abonnements) => {
                    if (state[subscription].status === status) {
                        subscriptions.push(subscription);
                    }
                });

                return subscriptions;
            },
        hasS3 (): boolean {
            return this.hasSubscriptionPaid(PBEnums.Abonnements.S3);
        },
        hasPartner (): boolean {
            return this.hasSubscriptionPaid(PBEnums.Abonnements.Partner);
        },
        hasDrive (): boolean {
            return this.hasSubscriptionPaid(PBEnums.Abonnements.Drive);
        },
        hasDrivePro (): boolean {
            return this.hasSubscriptionPaid(PBEnums.Abonnements.DrivePro);
        },
        hasAny () {
            return (
                this.hasS3
                || this.hasPartner
                || this.hasDrive
                || this.hasDrivePro
            );
        },

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ GET STATUS ]-----------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        getStatus: state => (subscription: PBEnums.Abonnements): StripeEnums.SubscriptionStatus => {
            return state[subscription].status;
        },
        getStatusS3 (): StripeEnums.SubscriptionStatus {
            return this.getStatus(PBEnums.Abonnements.S3);
        },
        getStatusPartner (): StripeEnums.SubscriptionStatus {
            return this.getStatus(PBEnums.Abonnements.Partner);
        },
        getStatusDrive (): StripeEnums.SubscriptionStatus {
            return this.getStatus(PBEnums.Abonnements.Drive);
        },
        getStatusDrivePro (): StripeEnums.SubscriptionStatus {
            return this.getStatus(PBEnums.Abonnements.DrivePro);
        },

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ IS READ ONLY ]---------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        isReadOnly (state): (subscription: PBEnums.Abonnements) => boolean {
            const hasAccess: StripeEnums.SubscriptionStatus[] = [ StripeEnums.SubscriptionStatus.Unpaid ];

            return (subscription: PBEnums.Abonnements): boolean => {
                return hasAccess.includes(state[subscription].status);
            };
        },
        isReadOnlyS3 (): boolean {
            return this.isReadOnly(PBEnums.Abonnements.S3);
        },
        isReadOnlyPartner (): boolean {
            return this.isReadOnly(PBEnums.Abonnements.Partner);
        },
        isReadOnlyDrive (): boolean {
            return this.isReadOnly(PBEnums.Abonnements.Drive);
        },
        isReadOnlyDrivePro (): boolean {
            return this.isReadOnly(PBEnums.Abonnements.DrivePro);
        },
        hasMultipleReadonly (): boolean {
            let count = 0;

            [
                PBEnums.Abonnements.S3,
                PBEnums.Abonnements.Partner,
                PBEnums.Abonnements.Drive,
                PBEnums.Abonnements.DrivePro
            ].forEach((subscription: PBEnums.Abonnements) => {
                if (this.isReadOnly(subscription)) {
                    count++;
                }
            });

            return count > 1;
        },

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ IS TRIALING ]----------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        isTrialS3: state => state[PBEnums.Abonnements.S3].trial !== null
            && state[PBEnums.Abonnements.S3].status
            === StripeEnums.SubscriptionStatus.Trialing,

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ GET INFO ]-------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        getInfoS3: state => state[PBEnums.Abonnements.S3],
        getInfoPartner: state => state[PBEnums.Abonnements.Partner],
        getInfoDrive: state => state[PBEnums.Abonnements.Drive],
        getInfoDrivePro: state => state[PBEnums.Abonnements.DrivePro],

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ GET TRIAL INFO ]-------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        getTrialInfoS3: state => state[PBEnums.Abonnements.S3].trial,

        // ---------------------------------------------------------------------------------------------------------
        // --------------------------------------[ GET QUOTA ]------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        getQuotaS3: state => state[PBEnums.Abonnements.S3].quota,
        getQuotaPartner: state => state[PBEnums.Abonnements.Partner].quota,

        isAllFetched: state => {
            return Object.values(state).every((subscription: { fetched: boolean }) => subscription.fetched);
        },
        isFetched:
            state => (subscription: PBEnums.Abonnements): boolean => {
                return state[subscription].fetched;
            },

        isUnlimitedS3: state => state[PBEnums.Abonnements.S3].quota === -1
    },
    actions: {
        /**
         * Enables parentHasPaid flag for a given subscription.
         *
         * If the subscription is Partner and the user has a parent, the subscription is changed to S3.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription type.
         * @param subscriptionObject - The subscription object from Stripe.
         * @return {void}
         */
        enableParentHasPaid (
            subscription: PBEnums.Abonnements,
            subscriptionObject: StripeRaw.Subscription
        ): void {
            if (!__pbUser().hasParent) {
                return;
            }

            if (subscription === PBEnums.Abonnements.Partner) {
                subscription = PBEnums.Abonnements.S3;
                this[subscription].parentHasPaid = true;
                this[PBEnums.Abonnements.S3].status
                    = subscriptionObject.status as StripeEnums.SubscriptionStatus;
            }
        },
        /**
         * Set the quota for a given subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription to set the quota for.
         * @param {number} quota - The quota value to set.
         * @return {void}
         */
        setQuota (subscription: PBEnums.Abonnements, quota: number): void {
            this[subscription].quota = quota;

            const trial = this[subscription].trial;
            if (trial) {
                trial.quota = quota !== -1 ? quota : 0.01;
                this[subscription].quota = quota !== -1 ? quota : 0.01;
            }
        },
        /**
         * Sets the description for a given subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription to set the description for.
         * @param {string} description - The description to set for the subscription.
         * @return {void}
         */
        setDescription (
            subscription: PBEnums.Abonnements,
            description: string
        ): void {
            this[subscription].description = description;
        },
        /**
         * Sets the startedAt date for the given subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription for which the startedAt date needs to be set.
         * @param {Date} date - The new startedAt date to be set.
         * @return {void}
         */
        setDate (subscription: PBEnums.Abonnements, date: Date): void {
            this[subscription].startedAt = date;
        },
        /**
         * Sets the interval for a given subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription to set the interval for.
         * @param {'month' | unknown} interval - The interval to set. Valid values are 'month' for monthly and unknown for annual.
         *
         * @return {void}
         */
        setInterval (
            subscription: PBEnums.Abonnements,
            interval: unknown
        ): void {
            this[subscription].interval
                = interval === "month" ? "label.monthly" : "label.yearly";
        },
        /**
         * Retrieves the quota for the specified subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription for which to retrieve the quota.
         * @returns {number} The quota for the specified subscription. If the quota is not defined, it returns 0.
         */
        getQuota (subscription: PBEnums.Abonnements): number | undefined {
            return this[subscription].quota ?? undefined;
        },
        async countSubscriptions (items: StripeRaw.Subscriptions | null): Promise<number> {
            let subscriptionCount = 0;
            if (items) {
                for (const item of items.data) {
                    const productType = await this.checkSubscription(item);
                    if (productType) {
                        subscriptionCount++;
                    }
                }
            }

            return subscriptionCount;
        },

        /**
         * Asynchronously checks the subscriptions of a given customer.
         *
         * @param {string} customerId - The ID of the customer.
         * @param {boolean} [checkingParent=false] - Optional flag to indicate whether checking for parent subscriptions.
         * @return {Promise<void>} - A promise that resolves when the subscriptions have been checked.
         */
        checkSubscriptions: async function (
            customerId: string,
            checkingParent: boolean = false
        ): Promise<void> {
            const subscriptions = await this.getSubscriptions(customerId);
            if (subscriptions.length === 0) {
                return;
            }
            for (const subscription of subscriptions) {
                if (subscription.items && subscription.items.data.length > 0) {
                    const subscriptionCount = await this.countSubscriptions(subscription.items);

                    for (const item of Object.values(subscription.items.data)) {
                        const productType = await this.checkSubscription(item);
                        if (productType === PBEnums.Abonnements.DrivePro) {
                            void __drivePro().createInstanceFromSubscription(
                                subscription,
                                subscriptionCount
                            );
                        }

                        if (productType) {
                            this.applySubscription(
                                productType,
                                item,
                                checkingParent,
                                subscription.status as StripeEnums.SubscriptionStatus,
                                subscription
                            );
                            this.setFetched(productType);
                        }
                    }
                } else {
                    const productType
                        = await this.checkSubscription(subscription);
                    this.applySubscription(
                        productType,
                        subscription,
                        checkingParent
                    );
                    if (productType) {
                        this.setFetched(productType);
                    }
                }
            }

            [
                PBEnums.Abonnements.S3,
                PBEnums.Abonnements.Partner,
                PBEnums.Abonnements.Drive,
                PBEnums.Abonnements.DrivePro
            ].forEach((subscription: PBEnums.Abonnements) => {
                this.setFetched(subscription);
            });

            if (this.hasS3) {
                void this.fetchS3SubscriptionInfos();
            }
        },
        /**
         * Applies a subscription by performing necessary checks and setting the status.
         *
         * @param {PBEnums.Abonnements | null} productType - The type of product for the subscription.
         * @param {StripeRaw.Subscription} subscription - The subscription object from Stripe.
         * @param {boolean} [checkingParent=false] - Indicates whether the subscription is being checked for the parent.
         * @param {StripeEnums.SubscriptionStatus} [mainSubscriptionStatus] - The status of the main subscription.
         * @param {StripeRaw.Subscription} [parentSubscription] - The parent subscription object.
         * @returns {void}
         */
        applySubscription (
            productType: PBEnums.Abonnements | null,
            subscription: StripeRaw.Subscription,
            checkingParent: boolean = false,
            mainSubscriptionStatus: StripeEnums.SubscriptionStatus | undefined = undefined,
            parentSubscription: StripeRaw.Subscription | undefined = undefined
        ): void {
            if (!productType) {
                console.warn("No product type found for", subscription);
                return;
            }

            const updateSub = this.checkPaidSubscription(
                productType,
                subscription,
                checkingParent,
                mainSubscriptionStatus
            );

            if (!updateSub) {
                console.warn("No update needed for", productType);
                return;
            }

            const mainSubscription = parentSubscription ?? subscription;

            this.checkTrialingSubscription(productType, mainSubscription);

            const productID = parentSubscription
                ? getProductIDFromItem(subscription)
                : getProductID(mainSubscription);

            // const productID = getProductID(mainSubscription);
            // debugger;
            if (!productID) {
                console.warn("No product ID found for", subscription);
                return;
            }

            __stripe()
                .getProduct(productID)
                .then((product: { description?: string }) => {
                    this.setProductInformations(
                        productType,
                        product,
                        subscription
                    );
                });
        },
        setProductInformations (
            productType: PBEnums.Abonnements,
            product: {
                description?: string;
            },
            subscription: StripeRaw.Subscription
        ): void {
            this.setDescription(
                productType,
                product?.description ?? subscription.plan?.nickname ?? ""
            );
            this.setDate(productType, new Date(subscription.start_date * 1000));
            this.setInterval(
                productType,
                subscription.plan?.interval ?? "month"
            );
        },
        /**
         * Checks a given subscription.
         *
         * @param {StripeRaw.Subscription} subscription - The subscription to check.
         * @return {void}
         */
        async checkSubscription (subscription: StripeRaw.Subscription): Promise<PBEnums.Abonnements | null> {
            const productType = await this.checkPlan(subscription);
            if (!productType) {
                return null;
            }

            return productType;
        },
        /**
         * Sets the status for a given subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription to set the status for.
         * @param {StripeEnums.SubscriptionStatus} status - The status to set.
         * @param checkingParent - Optional flag to indicate whether checking for parent subscriptions.
         * @param subscriptionObject
         * @return {void}
         */
        setStatus (
            subscription: PBEnums.Abonnements,
            status: StripeEnums.SubscriptionStatus,
            checkingParent: boolean = false,
            subscriptionObject?: StripeRaw.Subscription
        ): boolean {
            const subOrder = [
                StripeEnums.SubscriptionStatus.Active,
                StripeEnums.SubscriptionStatus.Trialing,
                StripeEnums.SubscriptionStatus.PastDue,
                StripeEnums.SubscriptionStatus.Unpaid,
                StripeEnums.SubscriptionStatus.Canceled,
                StripeEnums.SubscriptionStatus.Incomplete,
                StripeEnums.SubscriptionStatus.IncompleteExpired,
                StripeEnums.SubscriptionStatus.None
            ];

            // a subscription can only be upgraded, not downgraded
            if (
                subOrder.indexOf(status)
                >= subOrder.indexOf(this[subscription].status)
            ) {
                console.warn(
                    "Trying to downgrade subscription",
                    subscription,
                    "from",
                    this[subscription].status,
                    "to",
                    status
                );
                return false;
            }

            if (this[subscription].status === status) {
                console.warn("Status for", subscription, "is already", status);
                return false;
            }

            if (checkingParent) {
                if (
                    subscription === PBEnums.Abonnements.Partner
                    && this[PBEnums.Abonnements.S3].status
                    === StripeEnums.SubscriptionStatus.None
                ) {
                    this[PBEnums.Abonnements.S3].status = status;
                }

                return true;
            }

            this[subscription].status = status;

            if (
                subscriptionObject
                && "current_period_start" in subscriptionObject
            ) {
                this.setStartCycleDate(
                    subscription,
                    subscriptionObject.current_period_start
                );
            }

            return true;
        },
        /**
         * Retrieves the subscriptions for a given customer.
         *
         * @param {string} customerId - The ID of the customer.
         * @return {Promise<StripeRaw.Subscription[]>} - A promise that resolves to an array of subscriptions.
         */
        async getSubscriptions (customerId?: string | undefined): Promise<StripeRaw.Subscription[]> {
            await waitForKC();
            if (!__user().getUser.customerId) {
                await waitForPB();
            }

            if (!customerId) {
                customerId
                    = __stripe().getCustomerId || __stripe().getParentCustomerId;
            }

            if (!customerId) {
                console.warn("No customer ID found");
                __alert().add({
                    id: "no-payment",
                    type: "danger",
                    title: "Problème avec votre abonnement détecté",
                    message: `Nous vous invitons à contacter notre support :
            <a href="mailto:support@leviia.com" target="_blank">support@leviia.com</a>`,
                    isDismissible: false
                });
                __alert().remove("no-subscription-paid");
                return [];
            }

            const subscriptions = await useFetchRoute<StripeRaw.Subscriptions>(
                routesStripe.getSubscriptions,
                { customerId }
            );

            if (subscriptions.has_more) {
                console.error("Error: has_more is true, but we don't handle this case yet");
            }

            return subscriptions.data ?? [];
        },
        /**
         * Checks the subscription plan and returns the corresponding enum value.
         *
         * @param {StripeRaw.Subscription} subscription - The Stripe subscription object.
         * @param canHaveItems
         * @return {PBEnums.Abonnements | null} - The corresponding enum value if found; otherwise, null.
         */
        async checkPlan (
            subscription: StripeRaw.Subscription,
            canHaveItems: boolean = true
        ): Promise<PBEnums.Abonnements | null> {
            await this.getSubscriptions();
            const plan = subscription.plan;
            if (!plan) {
                // but, if it has items.data and items.data.length > 0, we can check the plan of all items
                if (
                    canHaveItems
                    && subscription.items
                    && subscription.items.data.length > 0
                ) {
                    for (const item of Object.values(subscription.items.data)) {
                        const productType = await this.checkPlan(item, false);
                        if (productType) {
                            return productType;
                        }
                    }
                }

                void callOnce(`plan-not-detected-1-${subscription.id}`, () => {
                    console.warn("No plan detected for sub", subscription.id);
                });
                return null;
            }

            const metadata = plan.metadata ?? {};
            // if metadata is empty, log an error
            if (Object.keys(metadata).length === 0) {
                void callOnce(`plan-metadata-empty-${subscription.id}`, () => {
                    console.error("Metadata is empty for plan", plan.id);
                });
                return null;
            }

            const subscriptionMetadata
                = metadata.abonnement ?? metadata.abonnement_kc ?? null;

            if (!subscriptionMetadata) {
                if (!metadata?.subscription_type) {
                    void callOnce(`plan-not-detected-2-${subscription.id}`, () => {
                        console.warn("No subscription detected for plan", plan.id);
                    });
                }

                return null;
            }

            for (const keycloakId of keycloakIdsOfSubscriptions) {
                if (keycloakId.allowedValues.includes(subscriptionMetadata)) {
                    return keycloakId.subscription;
                }
            }

            void callOnce(`plan-not-detected-3-${subscription.id}`, () => {
                console.warn(
                    "No subscription detected for plan",
                    plan.id,
                    "with subscription",
                    subscriptionMetadata
                );
            });
            return null;
        },
        /**
         * Sets the start cycle date for a given subscription.
         *
         * @param {PBEnums.Abonnements} productType - The type of subscription product.
         * @param {number} date - The date to set as the start cycle date.
         * @return {void} - This method does not return anything.
         */
        setStartCycleDate (
            productType: PBEnums.Abonnements,
            date: number
        ): void {
            this[productType].startsCycleDate = new Date(date * 1000);
        },
        /**
         * Checks if a subscription is paid.
         *
         * @param {PBEnums.Abonnements} productType - The type of product/subscription.
         * @param {StripeRaw.Subscription} subscription - The subscription object to check.
         * @param {boolean} [checkingParent=false] - Optional. Whether to check the parent subscription.
         * @param {StripeEnums.SubscriptionStatus} [mainSubscriptionStatus] - Optional. The status of the main subscription.
         * @return {void} - This method does not return anything.
         */
        checkPaidSubscription (
            productType: PBEnums.Abonnements,
            subscription: StripeRaw.Subscription,
            checkingParent: boolean = false,
            mainSubscriptionStatus: StripeEnums.SubscriptionStatus | undefined = undefined
        ): boolean {
            const isPaid = subscription.plan?.active ?? false;
            if (isPaid) {
                const newStatus: SubscriptionStatus = mainSubscriptionStatus
                    || subscription.status as SubscriptionStatus;
                const changeSub = this.setStatus(
                    productType,
                    newStatus,
                    checkingParent,
                    subscription
                );
                void checkNoSubscription(true);
                return changeSub ?? false;
            }

            return false;
        },
        /**
         * Checks if a subscription is in trialing status and updates the trial information if necessary.
         *
         * @param {PBEnums.Abonnements} productType - The type of subscription product.
         * @param {StripeRaw.Subscription} subscription - The subscription object from Stripe.
         * @returns {void}
         */
        checkTrialingSubscription (
            productType: PBEnums.Abonnements,
            subscription: StripeRaw.Subscription
        ): void {
            const isTrialing = subscription.status === "trialing";
            if (isTrialing) {
                const trial = this[productType].trial;
                if (!trial) {
                    this[productType].trial = {
                        startedAt: new Date(subscription.trial_start * 1000),
                        until: new Date(subscription.trial_end * 1000),
                        quota: 0.01
                    };
                }
            }
        },
        /**
         * Increases the size of the user's S3 storage.
         *
         * @param {number} size - The size to increment by.
         *
         * @return {void}
         */
        incrementUserS3Size (size: number): void {
            if (this[PBEnums.Abonnements.S3].size === undefined) {
                this[PBEnums.Abonnements.S3].size = 0;
            }

            this[PBEnums.Abonnements.S3].size += size;

            if (this[PBEnums.Abonnements.S3].quota) {
                if (
                    this[PBEnums.Abonnements.S3].size
                    >= toBytes(this[PBEnums.Abonnements.S3].quota, "To")
                ) {
                    this[PBEnums.Abonnements.S3].quotaReached = true;
                }
            }
        },
        /**
         * Increments the number of objects for the user's S3 subscription.
         *
         * @param {number} objects - The number of objects to increment by.
         *
         * @return {void}
         */
        incrementUserS3Objects (objects: number): void {
            this[PBEnums.Abonnements.S3].objects += objects;
        },
        /**
         * Resets the user's S3 statistics.
         *
         * @returns {void}
         */
        resetUserS3Stats (): void {
            this[PBEnums.Abonnements.S3].size = 0;
            this[PBEnums.Abonnements.S3].objects = 0;
            this[PBEnums.Abonnements.S3].quotaReached = false;
        },
        /**
         * Fetches the subscription information from S3 for the team.
         *
         * @returns A Promise that resolves to void.
         */
        async fetchS3SubscriptionInfos (): Promise<void> {
            await waitForPB();
            const unwatch = watch(
                () => __pbUser().getTeamId,
                async () => {
                    const teamId = __pbUser().getTeamId;
                    if (!teamId) {
                        console.warn("No team ID found");
                        return;
                    }

                    try {
                        const aboS3 = await PB.i
                            .collection("abo_s3")
                            .getFirstListItem<PBCollection.AboS3>(
                                `equipe="${teamId}"`,
                                {
                                    requestKey: `abo_s3-${teamId}`
                                }
                            );
                        if (aboS3) {
                            this.setQuota(PBEnums.Abonnements.S3, aboS3.quota);
                        }
                    } catch {
                        console.warn(`Can't fetch "abo_s3" for team "${teamId}"`);
                    } finally {
                        unwatch();
                    }
                },
                { immediate: true }
            );
        },
        /**
         * Sets the fetched property of the specified subscription.
         *
         * @param {PBEnums.Abonnements} subscription - The subscription to be updated.
         * @return {void}
         */
        setFetched (subscription: PBEnums.Abonnements): void {
            this[subscription].fetched = true;
        },
        /**
         * Checks the subscription status and displays an alert message if the status is past due.
         *
         * @param {string} emailSupport - The email address for contacting support.
         * @returns {void} - Does not return a value.
         */
        checkSubscriptionStatus (emailSupport: string): void {
            const hasPastDueSubscription = this.hasAnySubscriptionStatus(SubscriptionStatus.PastDue);

            if (hasPastDueSubscription) {
                const { t } = useI18n();
                const subs = this.getSubscriptionsByStatus(StripeEnums.SubscriptionStatus.PastDue);
                const sub
                    = subs.length > 0
                        ? subs.map(sub => t(`label.subscription.${sub}`))[0]
                        : "";

                // noinspection JSDeprecatedSymbols
                __alert().add({
                    id: "past-due-alert",
                    type: "warning",
                    title: t(
                        "notification.past_due.title",
                        { sub },
                        subs.length
                    ),
                    message: t(
                        "notification.past_due.message",
                        subs.length
                    ),
                    isDismissible: false,
                    actions: [
                        {
                            label: "label.parameters",
                            route: "/user/subscriptions",
                            type: "secondary",
                            icon: "settings"
                        },
                        {
                            label: "label.contact",
                            href: `mailto:${emailSupport}`,
                            type: "primary",
                            icon: "send-mail"
                        }
                    ]
                });
            }
        },
        /**
         * Check if all subscriptions are unpaid, subscription amount need to be more than 0
         * Create an alert if all subscriptions are unpaid
         *
         * @returns {void}
         */
        checkIfNoSubscriptionPaid (): void {
            // watchEffect(async () => {
            //     let allUnpaid: boolean = true
            //     const subTypes: PBEnums.Abonnements[] = []
            //     for (const sub of (await this.getSubscriptions())) {
            //         const subType = await this.checkPlan(sub)
            //         if (!subType) {
            //             continue
            //         }
            //         subTypes.push(subType)
            //     }
            //
            //     const unpaidStatuses: StripeEnums.SubscriptionStatus[] = [
            //         StripeEnums.SubscriptionStatus.Canceled,
            //         StripeEnums.SubscriptionStatus.Incomplete,
            //         StripeEnums.SubscriptionStatus.IncompleteExpired,
            //         StripeEnums.SubscriptionStatus.None
            //     ]
            //
            //     for (const subType of subTypes) {
            //         if (!unpaidStatuses.includes(this[subType].status)) {
            //             allUnpaid = false
            //         }
            //     }
            //
            //     if (allUnpaid) {
            //         const { $i18n } = useNuxtApp()
            //         const t = $i18n.t
            //         const subs = this.getSubscriptionsByStatus(StripeEnums.SubscriptionStatus.PastDue)
            //         const sub = subs.length > 0 ? subs.map(sub => t(`label.subscription.${sub}`))[0] : ''
            //
            //         function check () {
            //             console.log('check')
            //             if (!__alert().hasAlert('no-payment')) {
            //                 // noinspection JSDeprecatedSymbols
            //                 __alert().add({
            //                     id: 'no-subscription-paid',
            //                     type: 'info',
            //                     title: t('notification.no_active_sub.title'),
            //                     message: t('notification.no_active_sub.content', {
            //                         sub,
            //                         ...createLink('/user/subscriptions', 'params'),
            //                         ...createLink(`${config.URL_WEBSITE}`, 'website')
            //                     }, subs.length),
            //                     isDismissible: false
            //                 })
            //             } else {
            //                 __alert().remove('no-subscription-paid')
            //             }
            //         }
            //
            //         check()
            //     }
            // })
        },
        async isAllUnpaid (): Promise<boolean> {
            return (await this.getSubscriptions()).every((sub: StripeRaw.Subscription) => {
                const subType = this.checkPlan(sub);
                if (!subType) {
                    return false;
                }

                // if at least one subscription is not canceled, return
                const unpaidStatuses: StripeEnums.SubscriptionStatus[] = [ StripeEnums.SubscriptionStatus.Canceled ];

                return unpaidStatuses.includes(sub.status as StripeEnums.SubscriptionStatus);
            });
        },
        async isCanceled (sub: PBEnums.Abonnements): Promise<boolean> {
            await this.getSubscriptions();
            return this.isSubscriptionStatus(
                sub,
                StripeEnums.SubscriptionStatus.Canceled
            );
        },
        getProductName (subscription: StripeRaw.Subscription | StripeSubscription): string {
            if (!subscription || !subscription.plan) {
                return "";
            }

            return subscription.plan?.nickname ?? "";
        },
        hasNoSubscription (): boolean {
            return (
                !this.hasS3
                && !this.hasPartner
                && !this.hasDrive
                && !this.hasDrivePro
            );
        },
        async getStartsCycleDate (aboType: PBEnums.Abonnements): Promise<Date> {
            await waitForPB();
            return this[aboType].startsCycleDate;
        }
    }
});
