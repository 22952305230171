import { defineStore } from "pinia";

import { __pbUser } from "~/stores/pb-user.store";

import { getEnv } from "~/utils/polyfill";
import { getClusterRegionFromPBCluster } from "~/utils/tools";

import { routesCeph, routesKC } from "~/routes/declarations";

import type { Identifier } from "~/types/identifiants";
import type { PBCollection } from "~/types/pb/collections";
import { PBEnums } from "~/types/pb/enums";
import type { CreateUser, PBClient, PBCreateUser } from "~/types/pocketbase";

const config = getEnv();
const perPage = parseInt(config.PAGINATION_COUNT);

export const __clients = defineStore("clientsStore", {
    state: () => <{
        fetched: boolean
        loading: boolean
        clients: PBClient[]
    }>({
        fetched: false,
        loading: false,
        clients: []
    }),
    getters: {
        isFetched: state => state.fetched,
        isLoading: state => state.loading,

        getClients: state => state.clients.toSorted((a: { reference: string }, b: {
            reference: any
        }) => a.reference.localeCompare(b.reference)),
        getClient: state => (id: string): PBClient | undefined => state.clients
            .find((team: { id: string }) => team.id === id),
        getCount: state => state.clients.length,

        getTotalQuotaUsed: state => state.clients.reduce((total: any, client: {
            usedQuota: any
        }) => total + client.usedQuota, 0),
        getTotalQuotaAllowed: state => state.clients.reduce((total: any, client: {
            quotaAllowed: any
        }) => total + client.quotaAllowed, 0),
        getPagesAmount: state => Math.ceil(state.clients.length / perPage),
        getClientAtPage: state => (page: number): PBClient[] => {
            const start = (page - 1) * perPage;
            const end = start + perPage;
            return state.clients.slice(start, end);
        },

        isDeactivated: state => (clientId: string): boolean => {
            const client = state.clients.find((client: { id: string }) => client.id === clientId);
            if (!client) {
                return false;
            }
            return client.parent_deactivated || client.leviia_deactivated;
        }
    },
    actions: {
        /**
         * Extracts and adds client information to the given team.
         *
         * @param {PBCollection.Base & {
         *     reference: string;
         *     abonnements: PBEnums.Abonnements[];
         *     parent_deactivated: boolean;
         *     leviia_deactivated: boolean;
         *     customer_id?: string;
         *     parent?: PBCollection.Equipes['id']
         * }} team - The team to add client information to.
         * @returns {Promise<void>} - A promise that resolves when the client information is added.
         */
        extractAndAddClientInfo: async function (team: PBCollection.Base & {
            reference: string
            abonnements: PBEnums.Abonnements[]
            parent_deactivated: boolean
            leviia_deactivated: boolean
            customer_id?: string
            parent?: PBCollection.Equipes["id"]
        }) {
            try {
                const aboS3 = await PB.i.collection("abo_s3")
                    .getFirstListItem<PBCollection.AboS3>(`equipe="${team.id}"`);
                if (!aboS3) {
                    return;
                }

                this.addClient(team, PBEnums.Roles.Owner, aboS3.quota);

                void this.fetchS3SubscriptionInfos(team.id);
                void this.fetchClientUsedQuota(team.id);
            } catch {
                console.error("No abo s3 for: ", team.id);
            }
        },
        /**
         * Fetches clients for a given team.
         *
         * @param {string} teamId - The ID of the team. (optional)
         * @param {boolean} force - Whether to force fetch clients even if already fetched. Default is false. (optional)
         *
         * @returns {Promise<void>} - A promise that resolves when the clients are fetched.
         */
        async fetchClients (teamId?: PBCollection.Equipes["id"], force: boolean = false): Promise<void> {
            if (!force && this.fetched) {
                return;
            }

            const currentTeamId = teamId ?? __pbUser().getTeamId;

            if (!currentTeamId) {
                console.warn("Can't fetch clients: no team ID provided and no team found in user data");
                return;
            }

            this.loading = true;
            // @ts-ignore
            const teams = await PB.i.collection("equipes")
                .getFullList<PBCollection.Equipes>({
                    filter: `parent="${currentTeamId}"`,
                    requestKey: `equipes-${currentTeamId}`
                });

            const extractedPromises = teams.map((team: PBCollection.Equipes) => this.extractAndAddClientInfo(team));
            await Promise.all(extractedPromises);

            this.loading = false;
            this.fetched = true;
        },
        /**
         * Converts a collection of client and team data to a PBClient object.
         *
         * @param {PBCollection.Equipes} team - The team data object.
         * @param {PBEnums.Roles} role - The role of the client.
         * @returns {PBClient} - The converted client object.
         */
        collectionToClient (team: PBCollection.Equipes, role: PBEnums.Roles): PBClient {
            return {
                id: team.id,
                role,
                abonnements: team.abonnements,
                customer_id: team.customer_id,
                parent: team.parent,
                reference: team.reference,
                quotaAllowed: 0,
                created: team.created,
                parent_deactivated: team.parent_deactivated,
                leviia_deactivated: team.leviia_deactivated,

                allowed: 0,
                allowed_loading: false,
                updated_at: "",
                usedQuota: 0
            };
        },
        /**
         * Add a client to the collection.
         *
         * @param {PBCollection.Equipes} team - The team associated with the client.
         * @param {PBEnums.Roles} role - The role of the client in the team.
         * @param quota - The quota allowed for the client.
         * @returns {void}
         */
        addClient (team: PBCollection.Equipes, role: PBEnums.Roles, quota: number | undefined = undefined): void {
            const existingClient = this.getClient(team.id);
            if (existingClient) {
                this.updateClient(team, role);
                return;
            }

            const pbClient = this.collectionToClient(team, role);
            if (quota) {
                pbClient.quotaAllowed = quota;
            }
            this.clients.push(pbClient);
        },
        /**
         * Updates a client with the specified team and role.
         *
         * @param {PBCollection.Equipes} team - The team to assign to the client.
         * @param {PBEnums.Roles} role - The role to assign to the client.
         * @return {void}
         */
        updateClient (team: PBCollection.Equipes, role: PBEnums.Roles): void {
            this.clients = this.clients.map((c: { id: string }) => {
                if (c.id === team.id) {
                    return this.collectionToClient(team, role);
                }
                return c;
            }) as PBClient[];
        },
        /**
         * Fetches reseller subscription information for a given team and client.
         *
         * @param {string} teamId - The ID of the team.
         * @returns {Promise<void>} - A promise that resolves with the fetched subscription information.
         */
        async fetchS3SubscriptionInfos (teamId: PBCollection.Equipes["id"] | undefined): Promise<void> {
            if (!teamId) {
                return;
            }

            try {
                const aboS3 = await PB.i.collection("abo_s3")
                    .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`, {
                        requestKey: `abo_s3-${teamId}`
                    });
                const client = this.getClient(teamId);
                if (aboS3 && client) {
                    client.quotaAllowed = aboS3.quota;
                }
            } catch {
                console.warn(`Can't fetch "abo_s3" for team "${teamId}"`);
            }
        },
        /**
         * Refreshes the list of clients for a given team.
         *
         * @param {PBCollection.Equipes['id']} teamId - The ID of the team.
         * @return {Promise<void>} - A promise that resolves once the clients are refreshed.
         */
        async refreshClients (teamId: PBCollection.Equipes["id"]): Promise<void> {
            this.clients = [];
            await this.fetchClients(teamId, true);
        },
        /**
         * Retrieves the quota allowed for a given client.
         *
         * @param {string} teamId - The id of the team.
         * @return {number} - The quota allowed for the client. Returns 0 if the client is not found.
         */
        getQuotaAllowed (teamId: string): number {
            const client = this.getClient(teamId);

            if (!client) {
                return 0;
            }

            return client.quotaAllowed;
        },
        /**
         * Creates a new client with the given client information and allowed quota.
         *
         * @param {PBCreateUser} clientInfo - The information of the client.
         * @param {number} allowedQuota - The allowed quota for the client.
         * @returns {Promise<void>} A promise that resolves when the client is created successfully.
         */
        async createClient (clientInfo: PBCreateUser, allowedQuota: number): Promise<void> {
            const createUser: CreateUser = {
                email: clientInfo.email.trim(),
                firstname: clientInfo.prenom.trim(),
                lastname: clientInfo.nom.trim(),
                username: null
            };

            const { username, error }: CreateUser = await useFetchRoute(routesKC.createUser, null, createUser);

            if (error && error.length > 0) {
                console.error(`Error: ${error}`);
                return;
            }

            if (!username) {
                console.error("Username is null");
                return;
            }

            const client = {
                email: clientInfo.email.trim(),
                password: clientInfo.password.trim(),
                passwordConfirm: clientInfo.passwordConfirm.trim(),
                username,
                emailVisibility: true
            };

            const team = {
                reference: clientInfo.reference,
                abonnements: clientInfo.abonnements,
                parent: clientInfo.parent,
                parent_deactivated: false,
                leviia_deactivated: false
            };

            const pbClientPromise = PB.i.collection("clients").create<PBCollection.Clients>(client);

            const pbTeamPromise = PB.i.collection("equipes").create<PBCollection.Equipes>(team);

            const [ pbClient, pbTeam ] = await Promise.all([ pbClientPromise, pbTeamPromise ]);

            if (!pbTeam.id || !pbClient.id) {
                if (!pbClient.id) {
                    console.warn("Can't create client");
                }

                if (!pbTeam.id) {
                    console.warn("Can't create team");
                }

                return;
            }

            const pbTeamClient = await PB.i.collection("equipes_clients").create<PBCollection.EquipesClients>({
                equipe: pbTeam.id,
                client: pbClient.id,
                role: PBEnums.Roles.Owner
            });

            if (!pbTeamClient) {
                console.warn("Can't create team_client");
                return;
            }

            void PB.i.collection("clients").update<PBCollection.Clients>(pbClient.id, { membre: pbTeamClient.id });

            void PB.i.collection("abo_s3").create<PBCollection.AboS3>({ equipe: pbTeam.id, quota: allowedQuota });

            this.addClient(pbTeam, PBEnums.Roles.Owner, allowedQuota);
        },
        /**
         * Edit a client, team, and aboS3 information.
         *
         * @param {string} clientId - The ID of the client to be edited.
         * @param {Partial<PBCollection.Clients>} newClient - The new client information to be updated. Optional.
         * @param {Partial<PBCollection.Equipes>} newTeam - The new team information to be updated. Optional.
         * @param {Partial<PBCollection.AboS3>} newAboS3 - The new aboS3 information to be updated. Optional.
         *
         * @return {Promise<void>} - A promise that resolves with no value when the editing process is completed.
         */
        async editClient (
            clientId: PBCollection.Clients["id"],
            newClient: Partial<PBCollection.Clients> = {},
            newTeam: Partial<PBCollection.Equipes> = {},
            newAboS3: Partial<PBCollection.AboS3> = {}
        ): Promise<void> {
            const currentClient = this.getClient(clientId);
            if (!currentClient) {
                return;
            }

            if (newClient && Object.keys(newClient).length > 0) {
                await PB.i.collection("clients").update(clientId, newClient);
            }

            if (newTeam && Object.keys(newTeam).length > 0) {
                const { team } = await this.getTeamFromId(clientId);
                if (!team) {
                    return;
                }

                const updatedTeam = await PB.i.collection("equipes").update(team.id, newTeam);
                currentClient.reference = updatedTeam.reference;
            }

            this.clients = this.clients.map((c: { id: string }) => {
                if (c.id === clientId) {
                    return {
                        ...c,
                        ...newClient,
                        ...newTeam
                    };
                }

                return c;
            }) as PBClient[];

            if (newAboS3 && Object.keys(newAboS3).length > 0) {
                await this.updateAboS3(clientId, newAboS3);
            }
        },
        /**
         * Deletes a client with the provided clientId.
         *
         * @param {string} teamId - The unique identifier of the client to be deleted.
         * @return {Promise<void>} A promise that resolves when the client is successfully deleted.
         */
        async deleteClient (teamId: string): Promise<void> {
            const client = this.getClient(teamId);
            if (!client) {
                return;
            }

            const { team } = await this.getTeamFromId(teamId);
            if (team) {
                await this.deleteFromTeams(team.id);
            }

            this.clients = this.clients.filter((c: { id: any }) => c.id !== client.id);
        },
        /**
         * Deletes the team client from the "equipes_clients" collection.
         *
         * @param {PBClient} client - The client object.
         * @return {Promise<PBCollection.Equipes['id'] | null>} - Returns the ID of the deleted team or null if the team client was not found.
         */
        async deleteFromTeamClient (client: PBClient): Promise<PBCollection.Equipes["id"] | null> {
            const { team, equipesClients } = await this.getTeamFromId(client.id);
            if (team && equipesClients) {
                await PB.i.collection("equipes_clients").delete(equipesClients.id);
                return team.id;
            } else {
                console.warn(`Can't find a team for client "${client.id}"`);
                return null;
            }
        },
        /**
         * Retrieves the team and associated equipesClients for a given client ID.
         *
         * @param {string} teamId - The client ID to retrieve the team for.
         * @returns {Promise<{
         *     team: PBCollection.Equipes | null,
         *     equipesClients: PBCollection.EquipesClients | null
         * }>} - A promise that resolves to an object containing the team and equipesClients.
         *             - If equipesClients exist for the client, the team and equipesClients will be returned.
         *             - If no equipesClients exist for the client, null values will be returned for both team and equipesClients.
         */
        async getTeamFromId (teamId: string): Promise<{
            team: PBCollection.Equipes | null
            equipesClients: PBCollection.EquipesClients | null
        }> {
            const equipesClients = await PB.i.collection("equipes_clients")
                .getFirstListItem<PBCollection.EquipesClients & {
                    expand: {
                        equipe: PBCollection.Equipes
                    }
                }>(`equipe="${teamId}"`, {
                    expand: "equipe"
                });
            if (equipesClients) {
                return { team: equipesClients.expand.equipe, equipesClients };
            } else {
                console.warn(`Can't find "equipes_clients" for client "${teamId}"`);
                return { team: null, equipesClients: null };
            }
        },
        /**
         * Deletes a client from the "clients" collection.
         *
         * @param {PBClient} client - The client to be deleted.
         * @returns {Promise<void>} - A Promise that resolves when the client is deleted successfully.
         */
        async deleteFromClients (client: PBClient): Promise<void> {
            await PB.i.collection("clients").delete(client.id);
        },
        /**
         * Deletes a team from the "equipes" collection.
         *
         * @param {string} teamId - The ID of the team to be deleted.
         * @returns {Promise<void>} - A promise that resolves once the team is deleted.
         */
        async deleteFromTeams (teamId: string): Promise<void> {
            await PB.i.collection("equipes").delete(teamId);
        },
        /**
         * Deletes a team from the "abo_s3" collection.
         *
         * @param {string} teamId - The ID of the team.
         * @returns {Promise<void>} - A Promise that resolves when the deletion is complete.
         */
        async deleteFromAboS3 (teamId: string | undefined): Promise<void> {
            if (!teamId) {
                return;
            }

            const aboS3 = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`);
            if (aboS3) {
                await PB.i.collection("abo_s3").delete(aboS3.id);
            } else {
                console.warn(`Can't find "abo_s3" for team "${teamId}"`);
            }
        },
        /**
         * Updates the AboS3 information for a given client.
         *
         * @param {string} teamId - The ID of the client.
         * @param {object} aboS3 - The new AboS3 information to update.
         * @param {boolean|"!"} aboS3.desactivated - Whether the AboS3 is deactivated.
         * @param {number} aboS3.quota - The new quota for the AboS3.
         * @returns {Promise<void>} - A promise that resolves when the update is complete.
         */
        async updateAboS3 (teamId: string, aboS3: Partial<{
            quota: number
        }>): Promise<void> {
            const aboS3Obj = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`);
            if (!aboS3Obj) {
                return;
            }

            const newAboS3: Partial<{
                quota: number
            }> = {};

            if (aboS3.quota) {
                newAboS3.quota = aboS3.quota;
            }

            await PB.i.collection("abo_s3").update(aboS3Obj.id, newAboS3);

            const client = this.getClient(teamId);
            if (!client) {
                return;
            }

            if (newAboS3.quota !== undefined) {
                client.quotaAllowed = newAboS3.quota;
            }
        },
        /**
         * Toggles the activation status of the team object for a given client ID.
         *
         * @param {string} clientId - The ID of the client.
         * @return {Promise<void>} - A Promise that resolves when the team activation status is toggled.
         */
        async toggleAboS3Activation (clientId: string): Promise<void> {
            const { team } = await this.getTeamFromId(clientId);
            if (!team) {
                return;
            }

            await PB.i.collection("equipes").update(team.id, { parent_deactivated: !team.parent_deactivated });

            // disable in pbClient
            const client = this.getClient(clientId);
            if (!client) {
                return;
            }

            client.parent_deactivated = !team.parent_deactivated;
        },
        async getTeam (teamId: string): Promise<PBCollection.Equipes | undefined> {
            const team = await PB.i.collection("equipes")
                .getFirstListItem<PBCollection.Equipes>(`id="${teamId}"`);

            if (!team) {
                console.warn(`Can't find team "${teamId}"`);
            }

            return team;
        },
        /**
         * Fetches the used quota for a given client.
         *
         * @param {string} teamId - The ID of the team of client.
         * @returns {Promise<void>} - A promise that resolves when the used quota has been fetched.
         */
        async fetchClientUsedQuota (teamId: string): Promise<void> {
            const client = this.getClient(teamId);
            if (!client) {
                console.warn(`Can't fetch used quota for client "${teamId}"`);
                return;
            }

            const aboS3 = await PB.i.collection("abo_s3")
                .getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`);

            const identifiers = await PB.i.collection("identifiants_s3")
                .getFullList<PBCollection.IdentifiantS3>({
                    filter: `abo_s3="${aboS3.id}"`,
                    requestKey: `identifiants_s3-${teamId}`
                });

            if (identifiers.length <= 0) {
                console.warn(`No identifiers found for client "${teamId}"`);
                return;
            }

            identifiers.forEach((id: PBCollection.IdentifiantS3) => {
                const { region, cluster } = getClusterRegionFromPBCluster(id.cluster);
                useFetchRoute<Identifier>(routesCeph.getIdentifiant, {
                    identifiant: id.identifiant_s3,
                    region,
                    cluster
                })
                    ?.then((newIdentifier: any) => {
                        const quotaInKib = newIdentifier["user_quota"].max_size;
                        const quotaInTo = quotaInKib / (1000 * 1000 * 1000 * 1000);

                        if (client) {
                            client.usedQuota += quotaInTo;
                            client.allowed_loading = false;
                        }
                    });
            });
        }
    }
});
