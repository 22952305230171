import { defineStore } from "pinia";

import { keycloakIdsOfSubscriptions } from "~/stores/subscription.store";

import { routesNextcloud } from "~/routes/declarations";

import type { DriveProInfos, DriveProInstance, DriveSubscriptionType, UsedQuota } from "~/types/drive";
import { PBEnums } from "~/types/pb/enums";
import { StripeEnums } from "~/types/stripe/enums";
import type { StripeRaw } from "~/types/stripe/raw";

import { sortInstances } from "~/composables/utilities/sort";

export const __drivePro = defineStore(
    "drive-pro",
    {
        state: (): {
            isInstancesFetched: boolean
            instancesFetchedListeners: Array<() => void>
            instances: DriveProInstance[]
            instancesCloudInfos: Record<string, DriveProInfos>
        } => ({
            isInstancesFetched: false,
            instancesFetchedListeners: [],
            instances: [],
            instancesCloudInfos: {}
        }),
        getters: {
            getInstances: state => {
                return state.instances.sort(sortInstances);
            },
            getInstancesCount: state => {
                return state.instances.length;
            },
            getTotalStorage: (state): { used: number, quota: number } => {
                return state.instances.reduce((acc, instance) => {
                    return {
                        used: acc.used + instance.space.used,
                        quota: acc.quota + instance.space.quota
                    };
                }, { used: 0, quota: 0 });
            },
            getTotalUsers: (state): { used: number, quota: number } => {
                return state.instances.reduce((acc, instance) => {
                    return {
                        used: acc.used + instance.users.used,
                        quota: acc.quota + instance.users.quota
                    };
                }, { used: 0, quota: 0 });
            },
            getFistInstance: state => {
                if (state.instances.length === 0) {
                    return null;
                }

                return state.instances.sort(sortInstances)[0];
            },
            getInstanceById: state => (id: string): any => {
                return state.instances.find(instance => instance.id === id);
            },
            isLoading: state => (id: string): any => {
                return state.instances.find(instance => instance.id === id)?.isLoading ?? false;
            },
            getUsers: state => (id: string): UsedQuota => {
                const instance = state.instances.find(instance => instance.id === id);
                if (!instance) {
                    return { used: 0, quota: 0, unit: "user" };
                }

                return instance.users;
            },
            getQuota: state => (id: string): UsedQuota => {
                const instance = state.instances.find(instance => instance.id === id);
                if (!instance) {
                    return { used: 0, quota: 0, unit: "To" };
                }

                return instance.space;
            },
            getUrl: state => (id: string): string => {
                const instance = state.instances.find(instance => instance.id === id);
                if (!instance) {
                    return "";
                }

                return instance.url;
            },
            getCloudInfosError: state => (id: string): string | null => {
                return state.instancesCloudInfos[id]?.error ?? null;
            },
            getCloudInfosUsers: state => (id: string): number => {
                return state.instancesCloudInfos[id]?.nb_users ?? 0;
            },
            getCloudInfosSpace: state => (id: string): number => {
                return state.instancesCloudInfos[id]?.nb_size ?? 0;
            }
        },
        actions: {
            onInstancesFetched (listener: () => void) {
                if (this.isInstancesFetched) {
                    listener();
                    return;
                }

                this.instancesFetchedListeners.push(listener);
            },
            triggerInstancesFetched () {
                this.isInstancesFetched = true;
                this.instancesFetchedListeners.forEach(listener => listener());
            },
            getSubscriptionDescription (subscription: StripeRaw.Subscription): string {
                let description = "";
                if (subscription.plan && subscription.plan?.nickname) {
                    description = subscription.plan?.nickname;
                }

                if (!description && subscription.items && subscription.items.data.length > 0) {
                    subscription.items.data.forEach(item => {
                        for (const keycloakId of keycloakIdsOfSubscriptions) {
                            const metadata = item?.plan?.metadata;
                            if (!metadata) {
                                continue;
                            }

                            const subscriptionMetadata = metadata.abonnement ?? metadata.abonnement_kc ?? null;
                            if (subscriptionMetadata && keycloakId.allowedValues.includes(subscriptionMetadata)) {
                                description = item.plan?.nickname ?? "";
                            }
                        }
                    });
                }

                return description;
            },
            async createInstanceFromSubscription (
                subscription: StripeRaw.Subscription,
                subscriptionCount: number
            ): Promise<void> {
                try {
                    const subscriptionInfo = await PB.i.collection<StripeRaw.AboDrivePro>("abo_drive_pro")
                        .getFirstListItem(`subscription_id = "${subscription.id}"`);
                    if (!subscriptionInfo) {
                        console.warn(`No informations found for subscription pro: ${subscription.id}`);
                        return;
                    }

                    const description = this.getSubscriptionDescription(subscription);

                    const instance: DriveProInstance = {
                        isLoading: false,
                        isLoaded: false,

                        id: subscriptionInfo.id,
                        reference: subscriptionInfo.reference,
                        description,
                        url: subscriptionInfo.url,
                        isHDS: subscriptionInfo.hds,
                        users: { used: 0, quota: subscriptionInfo.quota_user, unit: "user" },
                        space: { used: 0, quota: subscriptionInfo.quota_to, unit: "To" },
                        instance: subscriptionInfo.instance,

                        startedAt: subscription.start_date,
                        canceledAt: subscription.canceled_at ?? undefined,

                        period: subscription.plan?.interval === "year" ? "yearly" : "monthly",

                        status: subscription.status,
                        statusType: this.getStatusType(subscription.status as StripeEnums.SubscriptionStatus),

                        subscriptionType: subscriptionInfo.subscription_type as DriveSubscriptionType,

                        subscription
                    };

                    this.instances.push(instance);

                    void this.fetchDriveInfos(instance.id);

                    if (this.instances.length === subscriptionCount) {
                        this.triggerInstancesFetched();
                    }
                } catch (e) {
                    console.warn(`No informations found for subscription pro: ${subscription.id}`);
                }
            },
            getDriveInfos: async function () {
                if (Object.keys(this.instancesCloudInfos).length <= 0) {
                    const infos
                        = await useFetchRoute<Record<string, DriveProInfos>>(routesNextcloud.driveProInfos) ?? [];
                    Object.keys(infos).forEach((key: string) => {
                        this.instancesCloudInfos[key] = infos[key];
                    });
                }

                return this.instancesCloudInfos;
            },
            async fetchDriveInfos (instance: string) {
                const currentInstance = this.getInstanceById(instance);
                if (!currentInstance) {
                    return;
                }

                if (!currentInstance.url) {
                    throw new Error("No url found for instance: " + currentInstance.reference);
                }

                currentInstance.isLoading = true;

                await this.getDriveInfos();

                __subscription().setStatus(PBEnums.Abonnements.DrivePro, StripeEnums.SubscriptionStatus.None);
                currentInstance.isLoading = false;

                if (this.getCloudInfosError(currentInstance.id)) {
                    console.warn(`Error while fetching drive pro "${currentInstance.reference}" at "${currentInstance.url}": ${this.getCloudInfosError(currentInstance.id)}`);
                }

                currentInstance.users = { ...currentInstance.users, used: this.getCloudInfosUsers(currentInstance.id) };
                currentInstance.space = { ...currentInstance.space, used: this.getCloudInfosSpace(currentInstance.id) };

                currentInstance.isLoaded = true;
            },
            getStatusType (status: StripeEnums.SubscriptionStatus): "success" | "warning" | "danger" | "info" {
                switch (status) {
                case StripeEnums.SubscriptionStatus.Active:
                case StripeEnums.SubscriptionStatus.Trialing:
                    return "success";
                case StripeEnums.SubscriptionStatus.PastDue:
                    return "warning";
                case StripeEnums.SubscriptionStatus.Unpaid:
                    return "danger";
                default:
                    return "info";
                }
            }
        }
    }
);
