import { __alert } from "~/stores/alert.store";
import { __identifier } from "~/stores/identifier.store";
import { __pbUser } from "~/stores/pb-user.store";

import type { Alert as AlertType } from "~/types/alert";
import { PBEnums } from "~/types/pb/enums";
import Abonnements = PBEnums.Abonnements;

const identifiersLoaded = ref<boolean>(false);

export async function checkNotifications (t: any, config: any): Promise<void> {
    await __pbUser().init();

    __identifier().getIdentifiers()?.then(() => {
        identifiersLoaded.value = true;
    });
    watchS3Trial(t);
    watchDeactivated();
    watchProblem(t, config);
    checkPaymentStatus(t, config);
    await checkNoSubscription();
}

function watchS3Trial (t: any): void {
    const unwatch = watchEffect(() => {
        if (!__user().user || !__subscription().hasS3 || !identifiersLoaded.value || !__subscription().isTrialS3) {
            setTimeout(() => {
                unwatch();
            }, 10000);
            return;
        }

        const s3Infos = __subscription().getInfoS3;
        const s3TrialInfos = __subscription().getTrialInfoS3;

        if (s3Infos && s3TrialInfos) {
            const daysUntilEnd = daysBetweenNow(s3TrialInfos.until);
            let message: string;
            let type = "info";

            if (__subscription().getInfoS3.quotaReached) {
                message = "modals.trial.content.quota_reached";
                type = "danger";
            } else {
                message = t("modals.trial.content.remaining_days", { days: daysUntilEnd, count: daysUntilEnd });
            }

            __alert().add({
                id: "s3-trial",
                type: type as AlertType["type"],
                title: "modals.trial.title",
                message,
                actions: [
                    {
                        label: "label.contact",
                        type: "primary",
                        icon: "send-mail",
                        href: "https://www.leviia.com/contact-pro/"
                    }
                ],
                isDismissible: false
            });

            unwatch();
        }
    });
}

function watchDeactivated (): void {
    let unwatch: any = null;
    unwatch = watchEffect(() => {
        if (__pbUser().isDeactivated
            || (
                __subscription().hasS3
                && __subscription().isReadOnlyS3
                && __pbUser().hasParent
            )
        ) {
            __alert().add({
                id: "reseller-deactivated",
                type: "danger",
                isDismissible: false,
                title: "modals.partners.disabled_by_distributor.title",
                message: "modals.partners.disabled_by_distributor.content"
            });

            if (unwatch) {
                unwatch();
            }
        }
    });

    setTimeout(() => {
        unwatch();
    }, 5000);
}

function watchProblem (
    t: any,
    config: any
): void {
    const supportEmail = config.SUPPORT_EMAIL;

    const unwatch = watchEffect(() => {
        if (__user().hasProblem && __pbUser().isInitialized) {
            __alert().add({
                id: "no-payment",
                type: "danger",
                title: "modals.subscription_problem.title",
                message: t("modals.subscription_problem.content", createEmailLink(supportEmail)),
                isDismissible: false
            });

            unwatch();
        }
    });

    setTimeout(() => {
        unwatch();
    }, 5000);
}

function checkPaymentStatus (t: any, config: any): void {
    const supportEmail = config.SUPPORT_EMAIL;

    const unwatch = watchEffect(() => {
        if (
            (
                !__subscription().isReadOnlyS3
                && !__subscription().isReadOnlyPartner
                && !__subscription().isReadOnlyDrive
                && !__subscription().isReadOnlyDrivePro
            ) || __pbUser().hasParent
        ) {
            setTimeout(() => {
                unwatch();
            }, 5000);

            return;
        }

        const hasMultipleReadonly = __subscription().hasMultipleReadonly;
        let subscriptionName = "";

        if (!hasMultipleReadonly) {
            if (__subscription().isReadOnlyS3) {
                subscriptionName = t("label.subscription.object_storage");
            } else if (__subscription().isReadOnlyPartner) {
                subscriptionName = t("label.subscription.partner_long");
            } else if (__subscription().isReadOnlyDrive) {
                subscriptionName = t("label.subscription.drive");
            } else if (__subscription().isReadOnlyDrivePro) {
                subscriptionName = t("label.subscription.drive_pro");
            }
        }

        const title = hasMultipleReadonly
            ? t("notification.suspended_account.title.multiple")
            : t("notification.suspended_account.title.single", { sub: subscriptionName });

        __alert().add({
            id: "unpaid",
            type: "danger",
            title,
            message: t("notification.suspended_account.content"),
            isDismissible: false,
            actions: [
                {
                    label: "label.parameters",
                    route: "/user/subscriptions",
                    type: "secondary",
                    icon: "settings"
                },
                {
                    label: "label.contact",
                    href: `mailto:${supportEmail}`,
                    type: "primary",
                    icon: "send-mail"
                }
            ]
        });

        unwatch();
    });
}

export async function checkNoSubscription (forceNoWait = false): Promise<void> {
    // eslint-disable-next-line func-style
    let unwatch = (): void => {
    };

    if (!forceNoWait) {
        await waitForSubscription(Abonnements.S3);
    }

    function checkSub (): void {
        if (__subscription().hasNoSubscription()) {
            __alert().add({
                id: "no-payment",
                type: "info",
                title: "notification.no_sub.title",
                message: "notification.no_sub.content",
                isDismissible: false,
                actions: [
                    {
                        label: "notification.no_sub.cta.website",
                        href: "https://www.leviia.com",
                        icon: "language",
                        type: "secondary"
                    },
                    {
                        label: "notification.no_sub.cta.parameters",
                        route: "/user/billing/details",
                        icon: "settings"
                    }
                ]
            });
        } else {
            __alert().remove("no-payment");
        }

        setTimeout(() => {
            unwatch();
        }, 30000);
    }

    if (forceNoWait) {
        checkSub();
    } else {
        unwatch = watch(() => __subscription().getInfoS3, checkSub, { deep: true, immediate: true });
    }
}
