export enum BucketLockingMode {
    Compliance = "COMPLIANCE",
    Gouvernance = "GOVERNANCE",
    Manuel = "NULL"
}

export enum BucketVersioningStatus {
    Enabled = "Enabled",
    Suspended = "Suspended"
}

export enum SwitchStatus {
    Enabled = "Enabled",
    Disabled = "Disabled"
}

export interface BucketQuota {
    check_on_raw: boolean
    enabled: boolean
    max_objects: number
    max_size: number
    max_size_kb: number
}

export type UsageS3 = {
    num_objects: number
    size: number
    size_actual: number
    size_kb: number
    size_kb_actual: number
    size_kb_utilized: number
    size_utilized: number
};

export interface CephBucket {
    bid: string
    bucket: string
    bucket_quota: BucketQuota
    creation_time: Date
    explicit_placement: any
    id: string
    index_type: string
    marker: string
    master_ver: string
    max_marker: string
    mtime: string
    num_shards: number
    owner: string
    placement_rule: string
    tenant: string
    usage: Record<string, UsageS3>
    ver: string
    zonegroup: string
    versioning?: "Enabled" | "Suspended"
    lock_enabled?: boolean
    lock_mode?: BucketLockingMode | null
    lock_retention_period_days?: number
    mfa_delete?: "Enabled" | "Disabled"
}

export type Bucket = {
    name: string
    created: Date
    createdFormatted?: string
    id: string
    idName: string
    size: number
    sizeFormatted?: string
    objects: number
    objectsFormatted?: string
    versioning: boolean
    locking: boolean
    lockingMode: BucketLockingMode
    lockingDuration: number
    twoFactorAuth: boolean
    notFound?: boolean
};

export type GetBucketsOptions = {
    page?: number
    limit?: number | string | null
};
