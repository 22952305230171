import moment from "moment-timezone";
import PocketBase from "pocketbase";

import { useCustomFetch } from "~/utils/fetch";
import { getEnv } from "~/utils/polyfill";

import { checkNoSubscription } from "~/composables/notifications";

const config = getEnv();

export type PBUser = {
    abonnements: string[]
    collectionId: string
    collectionName: string
    created: string
    customer_id: string
    emailVisibility: boolean
    id: string
    is_direct: boolean
    nom: string
    parent: string
    prenom: string
    reference: string
    updated: string
    username: string
    verified: boolean
};

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PB {
    private static _instance: PocketBase;

    public static get i (): PocketBase {
        if (!PB._instance) {
            PB._instance = new PocketBase(config.POCKET_BASE_URL ?? "");

            // PB._instance.afterSend = this.afterSend
        }

        return PB._instance;
    }

    public static async auth (): Promise<PocketBase | null> {
        let pbRes: { token: string, user: PBUser } | { error: string } | null = null;
        try {
            pbRes = await useCustomFetch<({ token: string, user: PBUser } | { error: string })>(
                `${config.POCKET_BASE_URL}/leviia_auth`,
            "POST",
            {
                "Content-Type": "application/json"
            },
            {
                AccessToken: keycloak.token
            }
            );
        } catch (e) {
            console.error(e);
            return null;
        }

        if (!pbRes || "error" in pbRes) {
            console.warn("User not in Backend");
            await checkNoSubscription(true);
            return null;
        }

        if ("token" in pbRes) {
            PB.i.authStore.save(pbRes.token);

            PB.i.autoCancellation(false);
        }

        return PB.i;
    }

    /**
     * Perform post-processing after sending a response.
     *
     * @param {Response} res - The response object.
     * @param {any} data - The data to be processed.
     * @return {any} - The processed data or the response object if data is empty.
     */
    public static afterSend (res: Response, data: any): any {
        if (data?.items && data.items.length > 0) {
            let newItems = data;
            if (data.items[0].id) {
                newItems = data.items.map((item: any) => {
                    const newItem = { ...item };

                    Object.keys(item).forEach(key => {
                        if (typeof newItem[key] === "string") {
                            newItem[key] = PB.convertToDateIfApplicable(item[key]);
                        }
                        if (key === "expand") {
                            Object.keys(newItem[key]).forEach(expandKey => {
                                const expandItem = newItem[key][expandKey];
                                Object.keys(expandItem).forEach(expandItemKey => {
                                    if (typeof expandItem[expandItemKey] === "string") {
                                        expandItem[expandItemKey] = PB
                                            .convertToDateIfApplicable(expandItem[expandItemKey]);
                                    }
                                });
                            });
                        }
                    });

                    return newItem;
                });
            }

            return Object.assign(data, { items: newItems });
        }

        return res;
    }

    /**
     * Convert the given element to a Date if it is a valid date string in the specified format.
     *
     * @param {string} element - The element to convert to a Date.
     * @returns {Date|string} - The converted Date if the element is a valid date string, otherwise the original element.
     */
    private static convertToDateIfApplicable (element: string): Date | string {
        const format = "YYYY-MM-DD HH:mm:ss.SSS[Z]";
        const date = moment(element, format, true).tz("Europe/Paris");

        if (date.isValid()) {
            return date.toDate();
        }

        return element;
    }
}
